<template>
  <div class="row cpes-page">
<!--    <div class="col-lg-12">-->
<!--      <div class="p-0 filter-block filters-wrap">-->
<!--        <div class>-->
<!--          <div class="filters">-->
<!--            <div class="filters__search">-->
<!--              <div class="input-group input-group-sm filters__input">-->
<!--                <span class="input-group-addon">-->
<!--                  <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>-->
<!--                  <i class="fa fa-search" v-else></i>-->
<!--                </span>-->
<!--                <input-->
<!--                  id="search"-->
<!--                  name="search"-->
<!--                  :placeholder="$t('general.search')"-->
<!--                  @keyup.13="searchInputHandler(searchValue)"-->
<!--                  class="form-control"-->
<!--                  v-model="searchValue"-->
<!--                  @input="searchInputHandler(searchValue)"-->
<!--                />-->
<!--                <span class="clear-input text-muted">-->
<!--                  <i class="fa fa-remove" v-if="searchValue && !searchSpinner" @click="searchInputHandler('')"></i>-->
<!--                </span>-->
<!--              </div>-->

<!--              <div class="input-group input-group-sm filters__location ml-1">-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-sitemap" :class="{ 'text-primary': filters.group !== 'No RRM filter' }"></i>-->
<!--                </span>-->
<!--                <select-->
<!--                  class="form-control form-control-sm filters__field"-->
<!--                  v-model="filters.group"-->
<!--                  @change="refreshCpesListQuery"-->
<!--                  :disabled="muteCpeFilters"-->
<!--                >-->
<!--                  <option :selected="true" :value="'No RRM filter'">{{ $t('RRM.allGroups') }}</option>-->
<!--                  <option v-for="rrm in rrmList" :value="rrm.id" :key="rrm.id">{{ rrm.name }}</option>-->
<!--                </select>-->
<!--              </div>-->

<!--&lt;!&ndash;              <div class="input-group input-group-sm filters__input ml-1">&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="fa fa-map-marker" :class="{ 'text-primary': filters.location !== 'All locations' }"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                <select&ndash;&gt;-->
<!--&lt;!&ndash;                  class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--&lt;!&ndash;                  ref="locationsSelect"&ndash;&gt;-->
<!--&lt;!&ndash;                  v-model="filters.location"&ndash;&gt;-->
<!--&lt;!&ndash;                  @change="this.refreshCpesListQuery"&ndash;&gt;-->
<!--&lt;!&ndash;                  :disabled="muteCpeFilters"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                  <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;                  <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                </select>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->

<!--&lt;!&ndash;              <div class="d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              class="d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="this.refreshCpesListQuery"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;              <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->

<!--              <div class="input-group input-group-sm filters__location ml-1 input-group-for-location-select-in-monitoringSNMP&#45;&#45;filters">-->


<!--                                                                   <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-monitoringSNMP&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="refreshCpesListQuery"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->


<!--              </div>-->



<!--              <div class="input-group input-group-sm filters__location ml-1">-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <img-->
<!--                    v-if="filters.models === 'All models'"-->
<!--                    class="img img-responsive cpe-icon"-->
<!--                    src="/static/img/router-icon.svg"-->
<!--                    alt-->
<!--                  />-->
<!--                  <img v-else class="img img-responsive cpe-icon" src="/static/img/router-icon-blue.svg" alt />-->
<!--                </span>-->
<!--                <select-->
<!--                  class="form-control form-control-sm filters__field"-->
<!--                  v-model="filters.models"-->
<!--                  @change="this.refreshCpesListQuery"-->
<!--                  :disabled="muteCpeFilters"-->
<!--                >-->
<!--                  <option :selected="true" :value="'All models'">{{ $t('general.allModels') }}</option>-->
<!--                  <option v-for="cpeModel in cpeModelsList" :value="cpeModel.id" :key="cpeModel.id">-->
<!--                    {{ cpeModel.description }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->

<!--              <div class="input-group input-group-sm filters__location ml-1">-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-tag" :class="{ 'text-primary': filters.tag !== 'All tags' }"></i>-->
<!--                </span>-->
<!--                <select-->
<!--                  class="form-control form-control-sm filters__field"-->
<!--                  v-model="filters.tag"-->
<!--                  @change="refreshCpesListQuery"-->
<!--                  :disabled="muteCpeFilters"-->
<!--                >-->
<!--                  <option :selected="true" :value="'All tags'">{{ $t('general.allTags') }}</option>-->
<!--                  <option :selected="true" v-if="cpesTagsCurrentList.length" :value="''">-->
<!--                    {{ $t('general.noTag') }}-->
<!--                  </option>-->
<!--                  <option v-for="tag in cpesTagsCurrentList" :value="tag" :key="tag">{{ tag }}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; filters &ndash;&gt;-->
<!--            <div class="filters__multiple">-->
<!--              <div class="btn-group btn-group-sm float-left" data-toggle="button">-->
<!--                <label class="btn btn btn-outline-primary" :class="{ active: filters.connectedFilter === true }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="connectedFilter"-->
<!--                    @click="setFilter('connectedFilter', true)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.connected') }}-->
<!--                </label>-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.connectedFilter === false }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="connectedFilter"-->
<!--                    @click="setFilter('connectedFilter', false)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.notConnected') }}-->
<!--                </label>-->
<!--              </div>-->
<!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === true }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="activeFilter"-->
<!--                    @click="setFilter('activeFilter', true)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.active') }}-->
<!--                </label>-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === false }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="activeFilter"-->
<!--                    @click="setFilter('activeFilter', false)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.notActive') }}-->
<!--                </label>-->
<!--              </div>-->
<!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 1 }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="interfacesFilter"-->
<!--                    @click="setFilter('interfacesFilter', 1)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.oneBand') }}-->
<!--                </label>-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 2 }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="interfacesFilter"-->
<!--                    @click="setFilter('interfacesFilter', 2)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.dualBand') }}-->
<!--                </label>-->
<!--                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 3 }">-->
<!--                  <input-->
<!--                    type="radio"-->
<!--                    name="interfacesFilter"-->
<!--                    @click="setFilter('interfacesFilter', 3)"-->
<!--                    :disabled="muteCpeFilters"-->
<!--                  />-->
<!--                  {{ $t('aps.wired') }}-->
<!--                </label>-->
<!--              </div>-->
<!--              &lt;!&ndash;              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">&ndash;&gt;-->
<!--              &lt;!&ndash;                <label class="btn btn-outline-primary" :class="{ active: filters.radarFilter }">&ndash;&gt;-->
<!--              &lt;!&ndash;                  <input&ndash;&gt;-->
<!--              &lt;!&ndash;                    type="radio"&ndash;&gt;-->
<!--              &lt;!&ndash;                    name="interfacesFilter"&ndash;&gt;-->
<!--              &lt;!&ndash;                    @click="setFilter('radarFilter', true)"&ndash;&gt;-->
<!--              &lt;!&ndash;                    :disabled="muteCpeFilters"&ndash;&gt;-->
<!--              &lt;!&ndash;                  />&ndash;&gt;-->
<!--              &lt;!&ndash;                  {{ $t('aps.radar') }}&ndash;&gt;-->
<!--              &lt;!&ndash;                </label>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">&ndash;&gt;-->
<!--              &lt;!&ndash;                <label class="btn btn-outline-primary" :class="{ active: filters.lastError === true }">&ndash;&gt;-->
<!--              &lt;!&ndash;                  <input&ndash;&gt;-->
<!--              &lt;!&ndash;                    type="radio"&ndash;&gt;-->
<!--              &lt;!&ndash;                    name="interfacesFilter"&ndash;&gt;-->
<!--              &lt;!&ndash;                    @click="setFilter('lastError', true)"&ndash;&gt;-->
<!--              &lt;!&ndash;                    :disabled="muteCpeFilters"&ndash;&gt;-->
<!--              &lt;!&ndash;                  />&ndash;&gt;-->
<!--              &lt;!&ndash;                  {{ $t('aps.lastError') }}&ndash;&gt;-->
<!--              &lt;!&ndash;                </label>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div v-if="isFiltersActive" class="mb-1">-->
<!--              <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--                <i class="fa fa-close"></i>-->
<!--                {{ $t('general.resetAllFilters') }}-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash;        <div class="pl-1 filters-add">&ndash;&gt;-->
<!--        &lt;!&ndash;          <button class="btn btn-info btn-md" @click="openCpeWizard" v-if="!isOperator">{{ $t('aps.addCpe') }}</button>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->


    <div class="col-lg-12">
      <div class="p-0   w-100p">
        <div class="w-100">
          <div class="filters w-100">
            <div class="row w-100">

              <div class="col-lg-3 mb-h pr-0">
                <div class="input-group input-group-sm filters__input">
                <span class="input-group-addon">
                  <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
                  <i class="fa fa-search" v-else></i>
                </span>
                  <input
                    id="search"
                    name="search"
                    :placeholder="$t('general.search')"
                    @keyup.13="searchInputHandler(searchValue)"
                    class="form-control"
                    v-model="searchValue"
                    @input="searchInputHandler(searchValue)"
                  />
                  <span class="clear-input text-muted">
                  <i class="fa fa-remove" v-if="searchValue && !searchSpinner"
                     @click="searchInputHandler('')"></i>
                </span>
                </div>
              </div>

                            <div class="col-lg-3 mb-h pr-0" v-if="areLocationsVisible">
                <div
                  class="input-group input-group-sm filters__location  input-group-for-location-select-in-monitoringSNMP--filters">


                                                                   <span
                                                                     class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-monitoringSNMP--filters"
                    enable-max-option-width
                    small-size
                       :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshCpesListQuery"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>


                </div>
              </div>

              <div class="col-lg-3 mb-h pr-0">
                <div class="input-group input-group-sm filters__location ">
                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-sitemap"
                     :class="{ 'text-primary': filters.group !== 'No RRM filter' }"></i>
                </span>
                  <select
                    class="form-control form-control-sm filters__field"
                    v-model="filters.group"
                    @change="refreshCpesListQuery"
                    :disabled="muteCpeFilters"
                  >
                    <option :selected="true" :value="'No RRM filter'">{{ $t('RRM.allGroups') }}
                    </option>
                    <option v-for="rrm in rrmList" :value="rrm.id" :key="rrm.id">{{ rrm.name }}
                    </option>
                  </select>
                </div>
              </div>




              <div class="col-lg-3 mb-h pr-0">
                <div class="input-group input-group-sm filters__location ml-1">
                <span class="input-group-addon filters__field-icon">
                  <img
                    v-if="filters.models === 'All models'"
                    class="img img-responsive cpe-icon"
                    src="/static/img/router-icon.svg"
                    alt
                  />
                  <img v-else class="img img-responsive cpe-icon"
                       src="/static/img/router-icon-blue.svg" alt/>
                </span>
                  <select
                    class="form-control form-control-sm filters__field"
                    v-model="filters.models"
                    @change="this.refreshCpesListQuery"
                    :disabled="muteCpeFilters"
                  >
                    <option :selected="true" :value="'All models'">{{ $t('general.allModels') }}
                    </option>
                    <option v-for="cpeModel in cpeModelsList" :value="cpeModel.id"
                            :key="cpeModel.id">
                      {{ cpeModel.description }}
                    </option>
                  </select>
                </div>
              </div>













<!--              <div class="input-group input-group-sm filters__location ml-1">-->
<!--                <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-tag" :class="{ 'text-primary': filters.tag !== 'All tags' }"></i>-->
<!--                </span>-->
<!--                <select-->
<!--                  class="form-control form-control-sm filters__field"-->
<!--                  v-model="filters.tag"-->
<!--                  @change="refreshCpesListQuery"-->
<!--                  :disabled="muteCpeFilters"-->
<!--                >-->
<!--                  <option :selected="true" :value="'All tags'">{{ $t('general.allTags') }}</option>-->
<!--                  <option :selected="true" v-if="cpesTagsCurrentList.length" :value="''">-->
<!--                    {{ $t('general.noTag') }}-->
<!--                  </option>-->
<!--                  <option v-for="tag in cpesTagsCurrentList" :value="tag" :key="tag">{{ tag }}</option>-->
<!--                </select>-->
<!--              </div>-->
            </div>
            <!-- filters -->
            <div class="filters__multiple">
              <div class="btn-group btn-group-sm float-left" data-toggle="button">
                <label class="btn btn btn-outline-primary" :class="{ active: filters.connectedFilter === true }">
                  <input
                    type="radio"
                    name="connectedFilter"
                    @click="setFilter('connectedFilter', true)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.connected') }}
                </label>
                <label class="btn btn-outline-primary" :class="{ active: filters.connectedFilter === false }">
                  <input
                    type="radio"
                    name="connectedFilter"
                    @click="setFilter('connectedFilter', false)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.notConnected') }}
                </label>
              </div>
              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === true }">
                  <input
                    type="radio"
                    name="activeFilter"
                    @click="setFilter('activeFilter', true)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.active') }}
                </label>
                <label class="btn btn-outline-primary" :class="{ active: filters.activeFilter === false }">
                  <input
                    type="radio"
                    name="activeFilter"
                    @click="setFilter('activeFilter', false)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.notActive') }}
                </label>
              </div>
              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">
                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 1 }">
                  <input
                    type="radio"
                    name="interfacesFilter"
                    @click="setFilter('interfacesFilter', 1)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.oneBand') }}
                </label>
                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 2 }">
                  <input
                    type="radio"
                    name="interfacesFilter"
                    @click="setFilter('interfacesFilter', 2)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.dualBand') }}
                </label>
                <label class="btn btn-outline-primary" :class="{ active: filters.interfacesFilter === 3 }">
                  <input
                    type="radio"
                    name="interfacesFilter"
                    @click="setFilter('interfacesFilter', 3)"
                    :disabled="muteCpeFilters"
                  />
                  {{ $t('aps.wired') }}
                </label>
              </div>
              <!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
              <!--                <label class="btn btn-outline-primary" :class="{ active: filters.radarFilter }">-->
              <!--                  <input-->
              <!--                    type="radio"-->
              <!--                    name="interfacesFilter"-->
              <!--                    @click="setFilter('radarFilter', true)"-->
              <!--                    :disabled="muteCpeFilters"-->
              <!--                  />-->
              <!--                  {{ $t('aps.radar') }}-->
              <!--                </label>-->
              <!--              </div>-->
              <!--              <div class="btn-group btn-group-sm float-left ml-1" data-toggle="button">-->
              <!--                <label class="btn btn-outline-primary" :class="{ active: filters.lastError === true }">-->
              <!--                  <input-->
              <!--                    type="radio"-->
              <!--                    name="interfacesFilter"-->
              <!--                    @click="setFilter('lastError', true)"-->
              <!--                    :disabled="muteCpeFilters"-->
              <!--                  />-->
              <!--                  {{ $t('aps.lastError') }}-->
              <!--                </label>-->
              <!--              </div>-->
            </div>
            <div v-if="isFiltersActive" class="mb-1">
              <WButton secondary outline sm
                @click="resetAllFilters"
              >
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </WButton>
            </div>
          </div>
        </div>

        <!--        <div class="pl-1 filters-add">-->
        <!--          <button class="btn btn-info btn-md" @click="openCpeWizard" v-if="!isOperator">{{ $t('aps.addCpe') }}</button>-->
        <!--        </div>-->
      </div>
    </div>


    <div class="col-lg-12">
      <div
        class="cpes"
        :class="{ 'edit-visible': editVisible || selectedCpesId.length, 'interface-visible': isCpeHasIntefacesView }"
      >
        <div class="cpes-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-aps" v-if="isCpesLoading"></div>-->
                  <div v-if="isCpesLoading || isSNMPConfigForManyCPEsLoading"><Loader-spinner /></div>
                  <div class="card-header d-flex align-items-center justify-content-between">
                    <div>
                      <i class="fa fa-align-justify"></i>
                      {{ $t('aps.cpesList') }}
                      <span class="text-muted ml-3" v-if="!isCpesLoading">
                        {{ $t('aps.tableView') }} {{ filterItems(cpesList).length }} {{ $t('aps.tableItemsOf') }}
                        {{ this.totalResult }}
                        <span v-if="selectedCpesId.length">,&nbsp;{{$t('general.selectedForTables')}}:&nbsp;{{ selectedCpesId.length }}</span>
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      <!--                      <Download-extract-v2-->
                      <!--                        v-if="cpesList.length"-->
                      <!--                        class="ml-h"-->
                      <!--                        :data="formatToCSV(cpesList)"-->
                      <!--                        :filename="`cpes-${moment(new Date()).format('lll')}`"-->
                      <!--                        :availableReports="{-->
                      <!--                          oldCsv: true,-->
                      <!--                          newCsv: false,-->
                      <!--                          oldXls: true,-->
                      <!--                          newXls: false,-->
                      <!--                          pdf: true-->
                      <!--                        }"-->
                      <!--                      />-->
                      <!--                      <div class="table-column-switcher">-->
                      <!--                        <button class="btn btn-link btn-lg p-h mt-0" @click="toggleColumnSettings">-->
                      <!--                          <i class="fa fa-gear"></i>-->
                      <!--                        </button>-->
                      <!--                        <div v-if="showColumnSwitcher" class="under-gear-menu table-column-switcher-column-list">-->
                      <!--                          <div-->
                      <!--                            class="table-column-switcher-column-item-wrapper mt-h"-->
                      <!--                            v-for="(columnSettings, columnKey) in tableSettings"-->
                      <!--                            :key="columnSettings.name"-->
                      <!--                            v-if="!(columnSettings.name === 'Model' || columnSettings.name === 'Name')"-->
                      <!--                          >-->
                      <!--                            <div class="form-group col-sm-12 mb-h">-->
                      <!--                              <label-->
                      <!--                                class="custom-control custom-checkbox"-->
                      <!--                                :class="{-->
                      <!--                                  'switch-disabled': columnSettings.name === 'Model' || columnSettings.name === 'Name'-->
                      <!--                                }"-->
                      <!--                              >-->
                      <!--                                <input-->
                      <!--                                  type="checkbox"-->
                      <!--                                  class="custom-control-input"-->
                      <!--                                  :checked="columnSettings.value"-->
                      <!--                                  v-model="columnSettings.value"-->
                      <!--                                  :id="columnSettings.name"-->
                      <!--                                  :disabled="columnSettings.name === 'Model' || columnSettings.name === 'Name'"-->
                      <!--                                  @change="toggleTableColumn(columnSettings.value, columnKey)"-->
                      <!--                                />-->
                      <!--                                <span aria-hidden="true" class="custom-control-indicator"></span>-->
                      <!--                                <span class="custom-control-description">-->
                      <!--                                  {{ columnSettings.name || columnSettings.hiddenName }}-->
                      <!--                                </span>-->
                      <!--                              </label>-->
                      <!--                            </div>-->
                      <!--                          </div>-->
                      <!--                          <button @click="toggleColumnSettings" class="btn btn-block btn-outline-primary mt-q btn-sm">-->
                      <!--                            {{ $t('general.save') }}-->
                      <!--                          </button>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                    </div>
                  </div>
                  <div class="card-block">
                    <table class="table">
                      <thead>
                        <tr>
                          <th v-if="!isOperator" class="align-middle">
                            <!--                            <Checkbox-component id="checkAll" :checked="isAllChecked" @click="checkAllCpes" />-->
                          </th>
                          <th
                            v-for="column in tableSettings"
                            :key="column.name"
                            v-if="column.value && !(editVisible && column.disabled)"
                            class="align-middle"
                          >
                            {{ column.name }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(cpe, index) in filterItems(cpesList)"
                          :key="cpe.id"
                          :data-index="index"
                          :data-id="cpe.id"
                          :data-status="cpe.config_status"
                          @click="toggleEdit"
                          class="cpe-row"
                          :class="{
                            selected: cpe.id === cpeSelectedId,
                            'cpe-updating': isBusy(cpe.id, cpe.config_status)
                          }"
                        >
                          <td v-if="!isOperator" class="align-middle">
                            <!--                            <Checkbox-component-->
                            <!--                              :id="`select-${cpe.id}`"-->
                            <!--                              :checked="selectedCpesId.includes(cpe.id)"-->
                            <!--                              :disabled="cpe.config_status === 'updating' || cpe.config_status === 'upgrading'"-->
                            <!--                              @click="checkCpe"-->
                            <!--                            />-->
                          </td>

                          <td v-if="tableSettings.name.value" class="td-name align-middle">
                            <span
                              :data-model="cpe.model.name"
                              class="badge-cpe badge-status ml-h"
                              :class="{
                                'badge-status__connected': cpe.connected,
                                'badge-status__disconnected': !cpe.connected
                              }"
                            >
                              <span v-if="cpe.name">{{ cpe.name }}</span>
                              <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                            </span>
                          </td>
                          <!--                          <td v-if="tableSettings.model.value && !editVisible" class="responsive-row align-middle">-->
                          <!--                            <span v-if="editVisible && cpe.model && cpe.model.name">{{ cutName(cpe.model.name) }}</span>-->
                          <!--                            <span v-else-if="cpe.model && cpe.model.name">-->
                          <!--                              {{ cpe.model.name }}-->
                          <!--                              <span v-if="cpe.model.short.toLowerCase().includes('cisco')">-->
                          <!--                                <img src="/static/img/cisco.svg" alt class="cisco-icon ml-h" />-->
                          <!--                              </span>-->
                          <!--                            </span>-->
                          <!--                            <span class="text-muted" v-else>{{ $t('misc.no_data') }}</span>-->
                          <!--                          </td>-->
                          <td
                            v-if="tableSettings.description.value && !editVisible"
                            class="responsive-row align-middle"
                          >
                            <span v-if="cpe.hasOwnProperty('description') && cpe.description !== ''">
                              {{ cpe.description }}
                            </span>
                            <span class="text-muted" v-else>{{ $t('misc.no_data') }}</span>
                          </td>
                          <td v-if="tableSettings.status.value" class="align-middle">
                            <div class="cpe-status">
                              <span v-if="isBusy(cpe.id, cpe.config_status)" class="text-info">
                                {{
                                  cpesIdsOperations.includes(cpe.id)
                                    ? $t('aps.updating')
                                    : $t(`aps.${cpe.config_status}`)
                                }}
                              </span>

                              <span v-else-if="cpe.config_status === 'ok'" class="text-success">
                                {{ $t('aps.ok') }}
                              </span>
                              <span v-else-if="cpe.config_status === 'offline'" class="text-muted">
                                {{ $t('aps.offline') }}
                              </span>

                              <span v-else-if="cpe.config_status === 'empty'" class="text-muted">
                                {{ $t('aps.empty') }}
                              </span>

                              <span v-else class="text-muted">{{ cpe.config_status }}</span>

                              <!--                              <span-->
                              <!--                                v-if="cpe.last_error && (cpe.last_error.description || cpe.last_error.type)"-->
                              <!--                                class="has-tooltip ml-h"-->
                              <!--                                v-tooltip.top-center="{ content: errorStatus(cpe.last_error) }"-->
                              <!--                                  @click.stop="$router.push({ name: 'Events', query: { id: cpe.id } })"-->
                              <!--                              >-->
                              <!--                                <i class="fa fa-warning text-danger"></i>-->
                              <!--                              </span>-->
                            </div>
                          </td>
                          <td v-if="tableSettings.server.value && !editVisible" class="responsive-row align-middle">
                            <span
                              v-if="
                                cpe.hasOwnProperty('state') &&
                                cpe.state.hasOwnProperty('l2tp_state') &&
                                cpe.state.l2tp_state.hasOwnProperty('host') &&
                                cpe.state.l2tp_state.host !== ''
                              "
                            >
                              {{
                                cpe.state.l2tp_state.host === 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'
                                  ? 'default'
                                  : cpe.state.l2tp_state.host
                              }}
                            </span>
                            <span class="text-muted" v-else>{{ $t('misc.no_data') }}</span>
                          </td>
                          <td v-if="tableSettings.snmpStatus.value" class="responsive-row align-middle">
                            <span v-if="isShowSNMPStatusesInTable">
                              <span
                                v-if="getSNMPConfigStatusForCPEByCPEId(cpe.id) === true"
                                class="badge badge-light badge-success"
                              >
                                {{ $t('general.on') }}
                              </span>
                              <span v-else class="badge badge-light badge-default">
                                {{ $t('general.off') }}
                              </span>
                            </span>
                            <span class="text-muted" v-else>{{ $t('misc.no_data') }}</span>
                          </td>
                          <td v-if="tableSettings.ip.value && !editVisible" class="responsive-row align-middle">
                            <span
                              v-if="
                                cpe.hasOwnProperty('state') &&
                                cpe.state.hasOwnProperty('l2tp_state') &&
                                cpe.state.l2tp_state.hasOwnProperty('local_addr') &&
                                cpe.state.l2tp_state.local_addr !== ''
                              "
                            >
                              {{ cpe.state.l2tp_state.local_addr }}
                            </span>
                            <span class="text-muted" v-else>{{ $t('misc.no_data') }}</span>
                          </td>
                        </tr>
                        <tr v-if="!filterItems(cpesList).length">
                          <td colspan="11" class="no-data-row">
                            <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="canLoadMore && !isCpesLoading && cpesList.length" class="card-block text-center p-0">
                      <WButton link
                        @click="loadMore()"
                      >
                        {{ $t('aps.tableLoad') }}
                        <span v-if="this.limit < this.totalResult - this.cpesList.length">{{ this.limit }}</span>
                        <span v-else>{{ this.totalResult - this.cpesList.length }}</span>
                        {{ $t('aps.tableOutOf') }} {{ this.totalResult - this.cpesList.length }}
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cpes-edit" v-if="editVisible && cpeEditCurrent && selectedCpesId.length == 0">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card mb-0" v-if="isSNMPMonitoringConfigLoading">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="loader-wrapper-in-SNMP-Monitoring-Config">
                      <Loader-spinner centered />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isSNMPMonitoringConfigLoading" class="card mb-0">
                <!--                <div class="loader loader-backdrop loader-backdrop-aps" v-if="isCpesLoading"></div>-->
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="edit-header">
                        <div class="edit-header__info mb-1 mt-1">
                          <div class="edit-header__name">
                            <span
                              class="badge-cpe badge-status badge-status--cpe ml-h h4"
                              :class="{
                                'badge-status__connected': cpeEditCurrent.connected,
                                'badge-status__disconnected': !cpeEditCurrent.connected
                              }"
                            >
                              <span v-if="cpeEditCurrent.name">{{ cpeEditCurrent.name }}</span>
                              <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                            </span>

                            <span class="ml-1 mb-0 cpe-edit-name">
                              <span
                                v-if="isBusy(cpeEditCurrent.id, cpeEditCurrent.config_status)"
                                class="badge badge-info text-sm"
                              >
                                {{
                                  cpesIdsOperations.includes(cpeEditCurrent.id)
                                    ? $t('aps.updating')
                                    : $t(`aps.${cpeEditCurrent.config_status}`)
                                }}
                              </span>

                              <span
                                v-else-if="cpeEditCurrent.config_status === 'ok'"
                                class="badge badge-success text-sm"
                              >
                                {{ $t('aps.ok') }}
                              </span>
                              <span
                                v-else-if="cpeEditCurrent.config_status === 'offline'"
                                class="badge badge-default text-sm"
                              >
                                {{ $t('aps.offline') }}
                              </span>
                              <span
                                v-else-if="cpeEditCurrent.config_status === 'empty'"
                                class="badge badge-default text-sm"
                              >
                                {{ $t('aps.empty') }}
                              </span>

                              <span v-else class="badge badge-default text-sm">{{ cpeEditCurrent.config_status }}</span>
                            </span>
                          </div>
                        </div>

                        <div class="edit-header__buttons-in-snmp-config-monitor">
                          <WButton secondary outline sm
                            @click="disableSingleEditMode"
                            v-tooltip.top-center="{ content: $t('general.close') }"
                          >
                            <i class="fa fa-close"></i>
                          </WButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-block pt-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row mt-1">
                        <div class="col-lg-7">
                          <ul class="list-unstyled cpe-info">
                            <li>
                              <strong>{{ $t('aps.cpeInfo') }}</strong>
                            </li>
                            <!--                            <li>-->
                            <!--                              {{ $t('aps.model') }}:-->
                            <!--                              <span class="text-muted">-->
                            <!--                                {{ cpeEditCurrent.model.name }} {{ isCisco ? '(Cisco)' : '' }}-->
                            <!--                              </span>-->
                            <!--                            </li>-->
                            <li v-if="cpeEditCurrent.state.network && cpeEditCurrent.state.network.macaddr">
                              {{ $t('aps.macaddr') }}:
                              <span class="text-muted">{{ cpeEditCurrent.state.network.macaddr }}</span>
                            </li>
                            <li>
                              {{ $t('aps.cpeId') }}:
                              <span class="text-muted">{{ cpeEditCurrent.id }}</span>
                            </li>
                          </ul>
                        </div>

                        <div class="col-lg-5">
                          <ul class="list-unstyled cpe-info">
                            <li>
                              <strong>
                                <span v-if="cpeEditCurrent.state.l2tp_state.local_addr !== ''">
                                  {{ $t('aps.connectionInfo') }}
                                </span>
                                <!--                                <span v-else>{{ $t('aps.connectionInfo') }}</span>-->
                              </strong>
                            </li>

                            <!--                            <li>-->
                            <!--                              {{ $t('aps.interfaceType') }}:-->
                            <!--                              <span-->
                            <!--                                v-if="-->
                            <!--                                  cpeEditCurrent.state.wan &&-->
                            <!--                                  cpeEditCurrent.state.wan.proto &&-->
                            <!--                                  cpeEditCurrent.state.wan.proto === `qmi`-->
                            <!--                                "-->
                            <!--                                class="text-muted"-->
                            <!--                              >-->
                            <!--                                Cellular modem-->
                            <!--                              </span>-->

                            <!--                              <span v-else class="text-muted">Ethernet</span>-->
                            <!--                            </li>-->

                            <!--                            <li-->
                            <!--                              v-if="-->
                            <!--                                cpeEditCurrent.state &&-->
                            <!--                                cpeEditCurrent.state.l2tp_state &&-->
                            <!--                                cpeEditCurrent.state.l2tp_state.tunnel_type &&-->
                            <!--                                cpeEditCurrent.state.l2tp_state.tunnel_type !== `none`-->
                            <!--                              "-->
                            <!--                            >-->
                            <!--                              {{ $t('aps.type') }}:-->
                            <!--                              <span class="text-muted">{{ cpeEditCurrent.state.l2tp_state.tunnel_type }}</span>-->
                            <!--                            </li>-->

                            <!--                            <li-->
                            <!--                              v-if="-->
                            <!--                                cpeEditCurrent.state &&-->
                            <!--                                cpeEditCurrent.state.network &&-->
                            <!--                                cpeEditCurrent.state.network.ipaddrs &&-->
                            <!--                                cpeEditCurrent.state.network.ipaddrs.length-->
                            <!--                              "-->
                            <!--                            >-->
                            <!--                              {{ $t('aps.ipaddr') }}:-->
                            <!--                              <span-->
                            <!--                                v-for="(ip, index) in cpeEditCurrent.state.network.ipaddrs"-->
                            <!--                                :key="ip.ipaddr"-->
                            <!--                                class="text-muted"-->
                            <!--                              >-->
                            <!--                                <a :href="'http://' + ip.ipaddr" v-if="cpeEditCurrent.connected && !isDisable">-->
                            <!--                                  {{ ip.ipaddr }}-->
                            <!--                                </a>-->
                            <!--                                <span v-else>{{ ip.ipaddr }}</span>-->
                            <!--                                <span v-if="index !== cpeEditCurrent.state.network.ipaddrs.length - 1">,</span>-->
                            <!--                              </span>-->
                            <!--                            </li>-->

                            <li v-if="cpeEditCurrent.state.l2tp_state && cpeEditCurrent.state.l2tp_state.local_addr">
                              {{ $t('aps.localAddr') }}:
                              <span class="text-muted">{{ cpeEditCurrent.state.l2tp_state.local_addr }}</span>
                            </li>

                            <li v-if="cpeEditCurrent.state.l2tp_state && cpeEditCurrent.state.l2tp_state.host_addr">
                              {{ $t('aps.hostAddr') }}:
                              <span class="text-muted">{{ cpeEditCurrent.state.l2tp_state.host_addr }}</span>
                            </li>
                            <li v-if="cpeEditCurrent.state.l2tp_state && cpeEditCurrent.state.l2tp_state.host">
                              {{ $t('aps.brokerHost') }}:
                              <span class="text-muted">
                                {{
                                  cpeEditCurrent.state.l2tp_state.host === 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'
                                    ? 'default'
                                    : cpeEditCurrent.state.l2tp_state.host
                                }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                  тут все формочки-->
                  <div
                    v-if="
                      cpeEditCurrent.hasOwnProperty('state') &&
                      cpeEditCurrent.state.hasOwnProperty('l2tp_state') &&
                      cpeEditCurrent.state.l2tp_state.hasOwnProperty('tunnel_type') &&
                      !this.checkIpsecTunnelType(cpeEditCurrent.state.l2tp_state.tunnel_type)
                    "
                    class="row"
                  >
                    <div class="col-lg-12">
                      <Alert class="alert-warning">
                        <template #text>
                          <span class="mr-h">
                            {{ $t('SNMPMonitoringConfig.SNMPMonitoringCPEMustBeAccessibleByIPFromServer') }}
                          </span>
                        </template>
                      </Alert>
                    </div>
                  </div>

                  <div
                    v-if="
                      cpeEditCurrent.config.hasOwnProperty('wmsnmpd') &&
                      cpeEditCurrent.config.wmsnmpd.hasOwnProperty('default') &&
                      cpeEditCurrent.config.wmsnmpd.default.hasOwnProperty('enabled') &&
                      cpeEditCurrent.config.wmsnmpd.default.enabled === false
                    "
                    class="row"
                  >
                    <div class="col-lg-12">
                      <Alert class="alert-warning">
                        <template #text>
                          <span class="mr-h">
                            {{ $t('SNMPMonitoringConfig.SNMPMonitoringNeedToEnableSNMPInCPESettings') }}
                          </span>
                        </template>
                      </Alert>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-lg-12">
                      <h6 class="mt-0">{{ $t('SNMPMonitoringConfig.SNMPMonitoringConfigTitle') }}</h6>
                      <!--                      enable-->
                      <div class="form-group mt-1">
                        <Switch-component
                          :disabled="disabledSNMPWalkerMonitoringConfigEdit"
                          id="snmp-config-enable"
                          name="snmp-config-enable"
                          v-model="updatedSNMPMonitoringConfig.enabled"
                          :label="$t('SNMPMonitoringConfig.SNMPMonitoringConfigEnabled')"
                        />
                        <span v-show="errors.has('snmp-config-enable')" class="help is-danger">
                          {{ errors.first('snmp-config-enable') }}
                        </span>
                      </div>
                      <div class="form-group mt-1">
                        <Switch-component
                          :disabled="disabledSNMPWalkerMonitoringConfigEdit"
                          id="snmp-config-check-agent"
                          name="snmp-config-check-agent"
                          v-model="updatedSNMPMonitoringConfig.check_agent"
                          :label="$t('SNMPMonitoringConfig.SNMPMonitoringConfigCheckAgent')"
                        />
                        <span v-show="errors.has('snmp-config-check-agent')" class="help is-danger">
                          {{ errors.first('snmp-config-check-agent') }}
                        </span>
                      </div>
                      <div class="form-group mt-1">
                        <Switch-component
                          :disabled="disabledSNMPWalkerMonitoringConfigEdit"
                          id="snmp-config-save-state"
                          name="snmp-config-save-state"
                          v-model="updatedSNMPMonitoringConfig.save_state"
                          :label="$t('SNMPMonitoringConfig.SNMPMonitoringConfigSaveState')"
                        />
                        <span v-show="errors.has('snmp-config-save-state')" class="help is-danger">
                          {{ errors.first('snmp-config-save-state') }}
                        </span>
                      </div>
                      <div class="form-group mb-1">
                        <label for="snmp-config-community">
                          {{ $t('SNMPMonitoringConfig.SNMPMonitoringConfigCommunity') }}
                        </label>
                        <input
                          :disabled="disabledSNMPWalkerMonitoringConfigEdit"
                          type="text"
                          id="snmp-config-community"
                          :placeholder="$t('SNMPMonitoringConfig.SNMPMonitoringConfigCommunityPlaceHolder')"
                          name="snmp-config-community"
                          v-validate="'max:50'"
                          class="form-control"
                          :class="{ input: true, 'is-danger': errors.has('description') }"
                          v-model="updatedSNMPMonitoringConfig.community"
                          :data-vv-as="$t('SNMPMonitoringConfig.SNMPMonitoringConfigCommunity')"
                        />
                        <span v-show="errors.has('snmp-config-community')" class="help is-danger">
                          {{ errors.first('snmp-config-community') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="actions-buttons">
                  <div>
                    <WButton success outline
                      v-if="isNewSNMPMonitoringConfigCreating && !disabledSNMPWalkerMonitoringConfigEdit"
                      @click="createSNMPMonitoringConfig()"
                      :disabled="errors.any() || disabledSNMPWalkerMonitoringConfigEdit"
                    >
                      {{ $t('general.create') }}
                    </WButton>
                    <WButton success outline
                      v-if="!isNewSNMPMonitoringConfigCreating && !disabledSNMPWalkerMonitoringConfigEdit"
                      @click="updateSNMPMonitoringConfig()"
                      :disabled="errors.any() || disabledSNMPWalkerMonitoringConfigEdit"
                    >
                      {{ $t('aps.save') }}
                    </WButton>
                    <WButton danger outline
                      customClass="ml-1"
                      v-if="!isNewSNMPMonitoringConfigCreating && !disabledSNMPWalkerMonitoringConfigEdit"
                      @click="deleteSNMPMonitoringConfig()"
                      :disabled="disabledSNMPWalkerMonitoringConfigEdit"
                    >
                      {{ $t('general.delete') }}
                    </WButton>
                  </div>
                  <div>
                    <WButton secondary outline
                      @click="disableSingleEditMode"
                    >
                      <span v-if="isDisable">{{ $t('aps.close') }}</span>
                      <span v-else>{{ $t('aps.cancel') }}</span>
                    </WButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cpes-edit" v-if="selectedCpesId.length > 0">
          <div class="row">
            <div class="col-lg-12">
              <div class="card mb-0">
                <div class="card-block mb-0" v-if="!hideCommonEdit">
                  <div class="h4 m-0">{{ $t('aps.multiEdit') }}</div>
                  <small class="text-muted">{{ $t('aps.multiEditMode') }}</small>
                  <WButton secondary outline fullWidth
                    customClass="mt-2"
                    @click="cancelCommonEdit"
                  >
                    {{ $t('aps.cancel') }}
                  </WButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
// import { Multiselect } from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';
// import VueSlider from 'vue-slider-component';
import Alert from '../../components/Universal/alert/alert.vue';
// import Modal from '../../components/Modal.vue';
// import rrmService from '../../services/rrmService';
import cpeService from '../../services/cpeService';
// import wlanService from '../../services/wlanService';
import helpers from '../../helpers';
// import cpePollingService from '../../services/cpePollingService';
import commonService from '../../services/commonService';
// import templatesService from '../../services/templatesService';
// import firewallService from '../../services/firewallService';
// import redirectsService from '../../services/redirectsService';
import 'vue-slider-component/theme/default.css';
import Filters from '../../filters';
import capabilities from '../../services/capabilities';
import { getDefaultTableSettings } from '@/table-settings';
import locationService from '../../services/locationService';
// import WiredConfig from '../../components/Aps/wiredConfig.vue';
// import statLogLbsConfig from '../../components/Aps/statLogLbsConfig.vue';
// import AccessControl from '../../components/Aps/accessControl.vue';
// import ChangeLocation from '../../components/general/modal/ChangeLocation.vue';
// import EmptyCellValue from '../../components/empty-cell-value.vue';
// import WarningLine from '../../components/warning-line.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
// import CheckboxComponent from '../../components/Universal/Checkbox-component.vue';
// import Info from '../../components/Universal/info-icon.vue';
// import MaxInactivityAbout from '../../components/Aps/MaxInactivityAbout.vue';
import SNMPMonitoringConfigService from '../../services/SNMPMonitoringConfigService';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

export default {
  name: 'SNMPMonitoringConfig',
  components: {
    // MaxInactivityAbout,
    // Alert,
    // Multiselect,
    // Modal,
    // VueSlider,
    // WiredConfig,
    // statLogLbsConfig,
    // AccessControl,
    // ChangeLocation,
    // EmptyCellValue,
    // WarningLine,
    SwitchComponent,
    Alert,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors
    // CheckboxComponent,
    // Info
  },
  props: {
    showDistressed: { type: Boolean, default: false }
  },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      beforeDestroyHookExecutedFlag: false,
      isShowSNMPStatusesInTable: true,
      snmpConfigListByCpeId: {},
      isNewSNMPMonitoringConfigCreating: false,
      updatedSNMPMonitoringConfig: {},
      currentSNMPMonitoringConfig: {},
      newSNMPMonitoringConfig: {
        base_location: '',
        ip: '',
        community: '',
        cpe: '',
        enabled: false,
        check_agent: false,
        save_state: false,
        host_name: ''
      },
      showingMaxinactivityModalInfoDetails: false,
      limit: 30,
      offset: 0,
      delayTimer: null,
      searchValue: '',
      searchSpinner: false,
      totalResult: false,
      canLoadMore: true,
      commandResult: '',
      showColumnSwitcher: false,
      cpeForDelete: false,
      checkboxValue: [],
      cpeModelData: false,
      groupSettings: true,
      assignCommonWlanMode: false,
      setTemplateMode: false,
      editCpesSettingsMode: false,
      uploadFWfile: false,
      selectFWfile: false,
      fwFromLink: false,
      logSettingsMode: false,
      accessControlMode: false,
      enableSaveChanges: false,
      FWUpgradeModal: false,
      notCorrectCapabilities: false,
      afterUpdate: true,
      isWlanDescription: false,
      interfaceSettings: {
        radio0: false,
        radio1: false,
        radio2: false
      },
      configFilterList: {
        blacklist: [],
        whitelist: []
      },
      firewallL3List: {
        ip_list: [],
        mac_list: []
      },
      forceFWObjects: false,
      selectedFW: false,
      TXPower: {
        processStyle: {
          backgroundImage: '-webkit-linear-gradient(left, #3498db, #f05b72)'
        }
        // disabled: [true, false]
      },
      rateControl: {
        '11n': {
          data: ['off', 'ht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        },
        '11ac': {
          data: ['off', 'ht', 'vht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        }
      },
      advancedSettings: {
        radio0: {
          maxClientsEnabled: false,
          rateControlEnabled: false
        },
        radio1: {
          maxClientsEnabled: false,
          rateControlEnabled: false
        },
        radio2: {
          maxClientsEnabled: false,
          rateControlEnabled: false
        }
      },
      rateValue: '',
      maxClientsEnabled: false,
      updateCommonCpesMode: false,
      commonUpdateCpesData: {
        description: '',
        model: '',
        commonSelectedWlans: this.commonSelectedWlans || []
      },
      logSettingsData: {
        stats_config: {
          enabled: true,
          reportperiod: ''
        },
        lbs_config: {
          enabled: true,
          reportperiod: ''
        },
        log_config: {
          enabled: true,
          reportperiod: ''
        }
      },
      commonSelectedWlans: [],
      statLogLbsmodal: false,
      selectedCpesId: [],
      newTags: [],
      newTag: '',
      allChecked: false,
      interfaceSelectedWlans: {
        radio0: [],
        radio1: [],
        radio2: []
        // сюда будем запихивать ключами id интерфейсов и массивами выбранные wlans из них
      },
      interfaceHiddenWlans: {
        radio0: [],
        radio1: [],
        radio2: []
      },
      interfaceCapabilities: {},
      searchQuery: '',
      updatedCpe: null,
      filters: {
        query: '',
        interfacesFilter: null,
        radarFilter: null,
        connectedFilter: null,
        activeFilter: null,
        scanningFilter: null,
        lastError: null,
        location: 'All locations',
        models: 'All models',
        tag: 'All tags',
        group: 'No RRM filter',
        with_childs: false
      },
      filteredCpes: [],
      hostname: '',
      commonDeletingWlansModal: false,
      commonDeletingTagsModal: false,
      deletingCpeModal: false,
      IPsecModal: false,
      rebootCpeRedundantModal: false,
      rebootCpeAgentModal: false,
      configModal: false,
      rebootCpeModal: false,
      resetCpeModal: false,
      resetBrokerAddress: '',
      commonDeletingCpesModal: false,
      cpeTagsModal: false,
      setTemplateModal: false,
      addTagsModal: false,
      newTemplateModal: false,
      newTemplateData: {
        name: '',
        description: '',
        base_location: '',
        model: '',
        is_auto: false,
        cpes: [],
        template: {
          cpe_config_template: false
        }
      },
      newUuid: '',
      invalidUuid: false,
      fileTypeError: false,
      WebAccessPort: '',
      SshAccessPort: '',
      WebAccessModal: false,
      calculatedCapabilities: {},
      channels40: [],
      channels80: [],
      CpesFwUpgradeMode: false,
      isFWUpgradeShow: false,
      isFirewallShow: false,
      newMacAddress: '',
      newL3MacAddress: '',
      newL3IPAddress: '',
      setTemplateId: '',
      isChangeLocationOpen: false,
      massLocationId: '' || this.$store.state.userData.base_location,
      configCommand: '',
      IPsecOption: ''
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    disabledSNMPWalkerMonitoringConfigEdit() {
      return this.isOperator;
    },
    isSNMPConfigForManyCPEsLoading() {
      return this.$store.state.loadingSNMPConfigForManyCPEs;
    },
    isSNMPMonitoringConfigLoading() {
      return this.$store.state.loadingSNMPConfig;
    },
    isCpeEditCurrentIpsecTunnelType() {
      if (this.cpeEditCurrent) {
        if (this.checkIpsecTunnelType(this.cpeEditCurrent.state.l2tp_state.tunnel_type)) {
          return true;
        }
        return false;
      }
      return false;
    },
    sortedInterfaces() {
      const interfaces = [...this.updatedCpe.config.wifi];
      return interfaces.sort((a, b) => a.frequency - b.frequency);
    },
    commonService() {
      return commonService;
    },
    isRootUser() {
      return this.$store.state.baseLocation === this.$store.state.userData.base_location;
    },
    modalOkTranslation() {
      return this.$i18n.t('aps.confirm');
    },
    modalCancelTranslation() {
      return this.$i18n.t('aps.cancel');
    },
    editVisible() {
      return Boolean(this.$store.state.editCpeId) || this.selectedCpesId.length;
    },
    muteEdit() {
      return this.$store.state.muteCpeEdit;
    },
    muteCpesDelete() {
      return this.$store.state.muteCpesDelete;
    },
    muteCpeFilters() {
      return this.$store.state.muteCpeFilters;
    },
    uploadFW() {
      return this.$store.state.uploadFW;
    },
    WebAccess() {
      return this.$store.state.WebAccess;
    },
    isCpesLoading() {
      return this.$store.state.loadingCpes;
    },

    isCpeHasIntefaces() {
      if (!this.updatedCpe.config.wifi || !this.updatedCpe.state.wifi) {
        return false;
      }
      return true;
    },
    isFiltersActive() {
      return (
        this.filters.interfacesFilter ||
        typeof this.filters.activeFilter === 'boolean' ||
        typeof this.filters.connectedFilter === 'boolean' ||
        this.filters.tag !== 'All tags' ||
        this.filters.group !== 'No RRM filter' ||
        this.filters.location !== 'All locations' ||
        this.filters.models !== 'All models' ||
        this.searchValue
      );
    },
    isCpeHasIntefacesView() {
      if (this.selectedCpesId.length > 0) return false;
      return true;
    },
    cpesList: {
      get() {
        return this.$store.state.cpesList;
      },
      set() {}
    },
    rrmList() {
      return this.$store.state.rrmList || [];
    },
    cpeModelsList() {
      return this.$store.state.cpeModelsList;
    },
    cpesTagsCurrentList() {
      return this.$store.state.cpesTagsList;
    },
    cpesTagsList() {
      const cpesTagsList = this.$store.state.cpesTagsList.filter((tag) => {
        if (this.updatedCpe.tags.includes(tag)) {
          return false;
        }
        return true;
      });

      return cpesTagsList;
    },
    cpesMultipleTagsList() {
      const cpesTagsList = this.$store.state.cpesTagsList.filter((tag) => {
        if (this.newTags.includes(tag)) {
          return false;
        }
        return true;
      });

      return cpesTagsList;
    },
    locationsList() {
      // return this.$store.state.locationsList.slice().sort((a, b) => {
      //   if (a.name > b.name) {
      //     return 1;
      //   }
      //   if (a.name < b.name) {
      //     return -1;
      //   }
      //   return 0;
      // });
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    templatesList() {
      const filteredByModel = this.$store.state.cpeTemplatesList.filter(
        (template) => template.model === this.updatedCpe.model.id
      );
      return filteredByModel;
    },
    locationsItems() {
      return this.$store.state.locationsItems;
    },
    redirectsList() {
      return this.$store.state.redirectsList;
    },
    cpeOperations() {
      return this.$store.state.cpeOperations;
    },
    cpesIdsOperations() {
      return helpers.combineOperationIdsArrayFromObjects(this.$store.state.cpeOperations);
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    wlansItems: {
      get() {
        return this.$store.state.wlansItems;
      },
      set() {}
    },
    hostsList: {
      get() {
        return this.$store.state.hostsList;
      },
      set() {}
    },
    sshAccess() {
      let link;
      if (API_URL) {
        const restApi = API_URL.split(':');
        link = restApi[1].slice(2);
      } else {
        link = window.location.host;
      }
      return `ssh -l root -p ${this.SshAccessPort} ${link}`;
    },
    webAccess() {
      let link;
      if (API_URL) {
        const restApi = API_URL.split(':');
        if (restApi[2]) {
          restApi.splice(2, 1);
          link = restApi.join(':');
        } else {
          link = restApi.join(':');
        }
      } else {
        link = `http://${window.location.host}`;
      }
      return `${link}:${this.WebAccessPort}`;
    },
    cpeEditCurrent() {
      let sameCpe = false;
      const { cpesList } = this.$store.state;
      let currentCpe;

      //  Определяем текущую cpe для редактирования
      if (cpesList.length) {
        for (const cpe of cpesList) {
          if (cpe.id === this.$store.state.editCpeId || cpe.id === this.selectedCpesId[0]) {
            currentCpe = cpe;
            break;
          }
        }
      }

      // Если новая точка, то копируем
      if (
        (this.updatedCpe && this.updatedCpe.id === this.$store.state.editCpeId) ||
        (this.updatedCpe && this.updatedCpe.id === this.selectedCpesId[0])
      ) {
        sameCpe = true;
      }
      if (
        (!sameCpe ||
          this.afterUpdate ||
          (this.updatedCpe &&
            helpers.combineModelIdsArrayFromObjectsArray(this.cpeOperations).includes(this.updatedCpe.id))) &&
        currentCpe
      ) {
        this.updatedCpe = JSON.parse(JSON.stringify(currentCpe));
      }
      // Находим capabilities у текущей модели
      if (!this.updatedCpe.config.wired) {
        this.updatedCpe.config.wired = [];
      }
      // заполняем временные white/blacklist
      this.configFilterList.blacklist = this.updatedCpe.config.lbs_config.blacklist;
      this.configFilterList.whitelist = this.updatedCpe.config.lbs_config.whitelist;

      if (this.updatedCpe.config.firewall.l3_filter) {
        if (
          !this.updatedCpe.config.firewall.l3_filter.mac_list ||
          !Array.isArray(this.updatedCpe.config.firewall.l3_filter.mac_list)
        ) {
          this.updatedCpe.config.firewall.l3_filter.mac_list = [];
        }
        this.firewallL3List.mac_list = this.updatedCpe.config.firewall.l3_filter.mac_list.sort();

        if (!Array.isArray(this.updatedCpe.config.firewall.l3_filter.ip_list)) {
          this.updatedCpe.config.firewall.l3_filter.ip_list = [];
        }
        this.firewallL3List.ip_list = this.updatedCpe.config.firewall.l3_filter.ip_list.sort();
      }

      if (!this.updatedCpe.config.log_config.log_port) {
        this.updatedCpe.config.log_config.log_port = '';
      }
      if (!this.updatedCpe.config.stats_config.reportperiod) {
        this.updatedCpe.config.stats_config.reportperiod = '';
      }
      if (!this.updatedCpe.config.lbs_config.reportperiod) {
        this.updatedCpe.config.lbs_config.reportperiod = '';
      }
      if (!this.updatedCpe.config.lbs_config.clienttimeout) {
        this.updatedCpe.config.lbs_config.clienttimeout = '';
      }

      const modelId = this.updatedCpe.model.id;
      const { cpeModelsList } = this.$store.state;

      if (cpeModelsList.length) {
        for (const model of cpeModelsList) {
          if (model.id === modelId) {
            this.cpeModelData = model;
            if (
              this.cpeModelData.name.toLowerCase().includes('cisco') ||
              this.updatedCpe.model.short.toLowerCase().includes('cisco')
            ) {
              this.cpeModelData.cisco = true;
            }

            for (const wiredInterface in this.cpeModelData.caps.wired) {
              if (this.updatedCpe && !helpers.transformArrayToObject(this.updatedCpe.config.wired)[wiredInterface]) {
                this.updatedCpe.config.wired.push({
                  id: wiredInterface,
                  vlans: [],
                  primary_vlan: 0,
                  fake_wlan: ''
                });
              }
              const wiredPorts = this.cpeModelData.caps.wired[wiredInterface].ports;
              const filteredPorts = wiredPorts.sort((curr, prev) => {
                if (curr.index > prev.index) {
                  return 1;
                }
                if (curr.index < prev.index) {
                  return -1;
                }
                return 0;
              });
              this.cpeModelData.caps.wired[wiredInterface].ports = filteredPorts;
            }
            break;
          } else {
            this.cpeModelData = false;
          }
        }
      }

      if (
        this.updatedCpe &&
        this.updatedCpe.config &&
        this.updatedCpe.config.wifi &&
        this.updatedCpe.state &&
        this.updatedCpe.state.wifi &&
        this.cpeModelData
      ) {
        this.updatedCpe.config.wifi.forEach((configInterface) => {
          if (!this.cpeModelData.caps.wifi[configInterface.id].txpwrlist.length) {
            return;
          }

          const currentWlans = [];
          const hiddenWlans = [];

          const stateInterface = this.updatedCpe.state.wifi[configInterface.id];
          const sortedTxpwrlist = this.cpeModelData.caps.wifi[configInterface.id].txpwrlist.sort(helpers.compareDbm);
          const maxTxPower = sortedTxpwrlist[sortedTxpwrlist.length - 1].dbm;
          const minTxPower = sortedTxpwrlist[0].dbm;

          if (!configInterface.power.range || configInterface.power.range.length !== 2) {
            if (this.cpeModelData && this.cpeModelData.caps.wifi[configInterface.id].txpwrlist) {
              configInterface.power.range = [minTxPower, maxTxPower];
            } else {
              configInterface.power.range = [0, 10];
            }
          }
          if (this.cpeModelData.cisco) {
            configInterface.power.range[0] = minTxPower;
          }

          if (this.wlansList) {
            configInterface.wlans.forEach((id) => {
              if (this.wlansItems[id]) {
                this.wlansItems[id].id = id;
                currentWlans.push(this.wlansItems[id]);
              } else {
                hiddenWlans.push(id);
              }
            });
          }

          if (!sameCpe || this.afterUpdate) {
            this.interfaceSelectedWlans[configInterface.id] = JSON.parse(JSON.stringify(currentWlans));
            if (!this.isRootUser) {
              this.interfaceHiddenWlans[configInterface.id] = JSON.parse(JSON.stringify(hiddenWlans));
            }
          }

          configInterface.wlans = helpers.combineIdsArrayFromObjectsArray(currentWlans);

          if (configInterface.maxclients) {
            this.advancedSettings[configInterface.id].maxClientsEnabled = true;
            // console.log(configInterface);
          } else {
            this.advancedSettings[configInterface.id].maxClientsEnabled = false;
          }

          if (stateInterface.bandmode === '11bgn') {
            stateInterface.bandmode = '11n';
          }

          if (!configInterface.bandmode) {
            configInterface.bandmode = stateInterface.bandmode;
          }

          if (!configInterface.bandwidth) {
            configInterface.bandwidth = stateInterface.bandwidth;
          }

          if (!configInterface.bandmode && !stateInterface.bandmode) {
            // проверяем на наличие доступного capabilities
            if (this.cpeModelData.caps.wifi && this.cpeModelData.caps.wifi[configInterface.id]) {
              if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.ac) {
                configInterface.bandmode = '11ac';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.n) {
                configInterface.bandmode = '11n';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.a) {
                configInterface.bandmode = '11a';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.b) {
                configInterface.bandmode = '11b';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.g) {
                configInterface.bandmode = '11g';
              }
            }
          }

          if (!configInterface.frequency) {
            if (
              this.cpeModelData &&
              this.cpeModelData.caps.wifi &&
              this.cpeModelData.caps.wifi[configInterface.id] &&
              this.cpeModelData.caps.wifi[configInterface.id].frequency !== 'Both'
            ) {
              Vue.set(configInterface, 'frequency', this.cpeModelData.caps.wifi[configInterface.id].frequency);
            } else if (stateInterface.frequency !== 'Both') {
              Vue.set(configInterface, 'frequency', stateInterface.frequency);
            } else {
              Vue.set(configInterface, 'frequency', '5');
            }
          }

          if (!configInterface.bandmode && !stateInterface.bandmode) {
            // проверяем на наличие доступного capabilities
            if (this.cpeModelData.caps.wifi && this.cpeModelData.caps.wifi[configInterface.id]) {
              if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.ac) {
                configInterface.bandmode = '11ac';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.n) {
                configInterface.bandmode = '11n';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.a) {
                configInterface.bandmode = '11a';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.b) {
                configInterface.bandmode = '11b';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.g) {
                configInterface.bandmode = '11g';
              }
            }
          }

          if (!configInterface.country) {
            configInterface.country = 'RU';
          }

          if (this.cpeModelData && this.cpeModelData.caps.wifi && this.cpeModelData.caps.wifi[configInterface.id]) {
            this.interfaceCapabilities[configInterface.id] = {
              selected: configInterface,
              capabilities: this.cpeModelData.caps.wifi[configInterface.id]
            };
            this.reverseCalculateCapabilities(configInterface, stateInterface);
            this.calculateCapabilities(
              configInterface,
              stateInterface,
              this.cpeModelData.caps.wifi[configInterface.id],
              configInterface.id
            );
          } else {
            this.interfaceCapabilities[configInterface.id] = {
              selected: configInterface
            };
          }
        });
      }

      this.afterUpdate = false;
      return currentCpe;
    },
    cpeSelectedId() {
      return this.$store.state.editCpeId;
    },
    hideCommonEdit() {
      return (
        this.assignCommonWlanMode ||
        this.updateCommonCpesMode ||
        this.logSettingsMode ||
        this.accessControlMode ||
        this.editCpesSettingsMode ||
        this.addTagsModal ||
        this.CpesFwUpgradeMode ||
        this.setTemplateMode
      );
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator' || this.isUpdating;
    },
    isUpdating() {
      return (
        (this.updatedCpe && this.cpeEditCurrent && this.cpeEditCurrent.config_status == 'updating') ||
        (this.updatedCpe && this.cpeEditCurrent && this.cpeEditCurrent.config_status == 'upgrading') ||
        (this.updatedCpe &&
          helpers.combineModelIdsArrayFromObjectsArray(this.cpeOperations).includes(this.updatedCpe.id)) ||
        (this.updatedCpe && this.isBusy(this.updatedCpe.id, this.updatedCpe.config_status))
      );
    },
    isOperator() {
      return this.$store.state.userData.role == 'operator';
    },
    isCisco() {
      let isCisco = false;
      if (this.cpeModelData && this.cpeModelData.name.toLowerCase().includes('cisco')) {
        isCisco = true;
      }
      if (this.updatedCpe && this.updatedCpe.model.short.toLowerCase().includes('cisco')) {
        isCisco = true;
      }
      return isCisco;
    },
    isAllChecked() {
      let result = true;
      let cpesIdsList;
      let notUpgradingCpes = [];
      if (this.cpesList) {
        notUpgradingCpes = this.cpesList.filter((cpe) => cpe.config_status !== 'updating');
        cpesIdsList = helpers.combineIdsArrayFromObjectsArray(notUpgradingCpes);
      }
      if (cpesIdsList && cpesIdsList.length && this.selectedCpesId && this.selectedCpesId.length) {
        cpesIdsList.forEach((cpe_id) => {
          if (!this.selectedCpesId.includes(cpe_id)) {
            return (result = false);
          }
        });
      } else {
        return (result = false);
      }
      return result;
    },
    userDataLocation() {
      this.filters.base_location = this.$store.state.userData.base_location;
      return this.$store.state.userData.base_location;
    },
    isNoData() {
      return this.filteredCpes.length;
    },
    computedCapabilities() {
      return this.calculatedCapabilities;
    },
    checkGroupSettings() {
      return this.groupSettings;
    },
    tableSettings() {
      return getDefaultTableSettings(this.$i18n).SNMPMonitoringConfig;
    },
    firewallsList() {
      return this.$store.state.firewallsList;
    }
  },
  watch: {
    cpeOperations() {},
    cpesList() {
      this.afterUpdate = true;
      // cpePollingService.startPolling(this);

      // смотрим какие точки показаны на экране и запрашиваем связанные с ними SNMP объекты
      const cpeIds = [];
      for (const cpeItemIndex in this.cpesList) {
        cpeIds.push(this.cpesList[cpeItemIndex].id);
      }
      SNMPMonitoringConfigService.getSNMPConfigForCPEsByCPEIDs(this, cpeIds);
    },
    updatedCpe: {
      handler(val, oldVal) {
        if (!oldVal) {
          this.enableSaveChanges = false;
        } else if (val && oldVal && val.id !== oldVal.id) {
          this.enableSaveChanges = false;
        } else if (!val) {
          this.enableSaveChanges = false;
        } else {
          this.enableSaveChanges = true;
        }
      },
      deep: true
    }
  },
  methods: {
    getSNMPConfigStatusForCPEByCPEId(cpeId) {
      if (this.snmpConfigListByCpeId.hasOwnProperty(cpeId)) {
        if (this.snmpConfigListByCpeId[cpeId].hasOwnProperty('enabled')) {
          if (this.snmpConfigListByCpeId[cpeId].enabled === true) {
            return true;
          }
        }
      }
      return false;
    },
    setSNMPConfigForCPE(snmpObj) {
      if (snmpObj.cpe !== '') {
        this.snmpConfigListByCpeId[snmpObj.cpe] = snmpObj;
        this.redrawSNMPStatusesInTable();
      }
    },
    deleteSNMPConfigForCPE(snmpObj) {
      if (this.snmpConfigListByCpeId.hasOwnProperty(snmpObj.cpe)) {
        delete this.snmpConfigListByCpeId[snmpObj.cpe];
        this.redrawSNMPStatusesInTable();
      }
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshCpesListQuery()


    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    redrawSNMPStatusesInTable() {
      this.isShowSNMPStatusesInTable = false;
      this.$nextTick(() => {
        this.isShowSNMPStatusesInTable = true;
      });
    },
    sideEffectOutput(value) {
      // console.log(value);
      return value;
    },
    loadMore() {
      this.offset++;
      cpeService.getPaginatedCpes(this, this.limit, this.offset);
    },
    searchInputHandler(value) {
      this.cancelEdit();
      this.searchValue = value;
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.refreshCpesListQuery();
      }, 1000);
    },
    toggleColumnSettings() {
      this.showColumnSwitcher = !this.showColumnSwitcher;
    },
    toggleTableColumn(value, key) {
      // deprecated and deleted setTableSettings
      // this.$store.commit('setTableSettings', {
      //   tableName: 'cpe',
      //   key,
      //   value
      // });
    },
    // reverseCalculateCapabilities(radioInterface, state) {
    //   const config = radioInterface;
    //
    //   if (config.bandmode === '11g' && config.frequency === '2.4') {
    //     if (config.bandwidth !== 'auto') {
    //       config.bandmode = '11n';
    //     }
    //   }
    //   if (config.bandmode === '11a' && config.frequency === '5') {
    //     if (config.bandwidth === 'auto') {
    //       config.bandmode = '11a';
    //     } else if (config.bandwidth.indexOf('HT') === 0) {
    //       config.bandmode = '11n';
    //     } else if (config.bandwidth.indexOf('VHT') === 0) {
    //       config.bandmode = '11ac';
    //     }
    //   }
    // },

        reverseCalculateCapabilities(radioInterface, state) {
      const config = radioInterface;

      if (config.bandmode === '11g' && config.frequency === '2.4') {
        if (config.bandwidth !== 'auto') {
          if (config.bandwidth.indexOf('HE') === 0) {
            config.bandmode = '11ax';
          } else {
            config.bandmode = '11n';
          }

        }
      }
      if (config.bandmode === '11a' && config.frequency === '5') {
        if (config.bandwidth === 'auto') {
          config.bandmode = '11a';
        } else if (config.bandwidth.indexOf('HT') === 0) {
          config.bandmode = '11n';
        } else if (config.bandwidth.indexOf('VHT') === 0) {
          config.bandmode = '11ac';
        } else if (config.bandwidth.indexOf('HE') === 0) {
          config.bandmode = '11ax';
        }
      }
    },

    ssidWithDescription({ ssid, description }) {
      if (this.isWlanDescription && description) {
        return `${ssid}: ${commonService.cutName(description)}`;
      }
      return `${ssid}`;
    },
    // isModeAllowed(frequency, mode, hwmodelist) {
    //   if (frequency === '2.4') {
    //     const modes24 = {
    //       b: hwmodelist.b,
    //       g: hwmodelist.g,
    //       n: hwmodelist.n
    //       // 'ac': hwmodelist.ac
    //     };
    //     return modes24[mode];
    //   }
    //   const modes5 = {
    //     a: hwmodelist.a,
    //     n: hwmodelist.n,
    //     ac: hwmodelist.ac
    //   };
    //   return modes5[mode];
    // },

    isModeAllowed(frequency, mode, hwmodelist) {
      if (frequency === '2.4') {
        const modes24 = {
          b: hwmodelist.b,
          g: hwmodelist.g,
          n: hwmodelist.n,
          // 'ac': hwmodelist.ac
          ax: hwmodelist.ax
        };
        return modes24[mode];
      }
      const modes5 = {
        a: hwmodelist.a,
        n: hwmodelist.n,
        ac: hwmodelist.ac,
        ax: hwmodelist.ax
      };
      return modes5[mode];
    },

    handleFrequencyChange(interfaceSelectedData, stateInterface, interfaceCapabilities, interfaceName) {
      if (interfaceSelectedData.frequency === '2.4') {
        if (interfaceCapabilities.hwmodelist.b) {
          interfaceSelectedData.bandmode = '11b';
        } else if (interfaceCapabilities.hwmodelist.g) {
          interfaceSelectedData.bandmode = '11g';
        } else if (interfaceCapabilities.hwmodelist.n) {
          interfaceSelectedData.bandmode = '11n';
        }
      } else if (interfaceSelectedData.frequency === '5') {
        if (interfaceCapabilities.hwmodelist.a) {
          interfaceSelectedData.bandmode = '11a';
        } else if (interfaceCapabilities.hwmodelist.n) {
          interfaceSelectedData.bandmode = '11n';
        } else if (interfaceCapabilities.hwmodelist.ac) {
          interfaceSelectedData.bandmode = '11ac';
        }
      }
      this.calculateCapabilities(interfaceSelectedData, stateInterface, interfaceCapabilities, interfaceName);
      this.clearChanList(interfaceName);
    },
    handleModeChange(interfaceSelectedData, stateInterface, interfaceCapabilities, interfaceName) {
      if (interfaceSelectedData.bandmode != '11ac' || interfaceSelectedData.bandmode != '11n') {
        interfaceSelectedData.require_mode = 'off';
      }
      if (
        interfaceSelectedData.bandmode === '11a' ||
        interfaceSelectedData.bandmode === '11b' ||
        interfaceSelectedData.bandmode === '11g'
      ) {
        interfaceSelectedData.bandwidth = 'auto';
      }
      this.calculateCapabilities(interfaceSelectedData, stateInterface, interfaceCapabilities, interfaceName);
    },
    // calculateCapabilities(configInterface, stateInterface, interfaceCapabilities, interfaceName) {
    //   if (!interfaceCapabilities) {
    //     return;
    //   }
    //
    //   const config = configInterface;
    //
    //   let selectedChannel = null;
    //   if (stateInterface) {
    //     selectedChannel = stateInterface.channel;
    //   } else {
    //     selectedChannel = configInterface.channels[0];
    //   }
    //   if (!interfaceName) return;
    //
    //   this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);
    //
    //   if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
    //     config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
    //   }
    //
    //   // validate channels for HT40 & 2.4
    //   if (
    //     this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'HT40'
    //     );
    //   }
    //   // end validate
    //
    //   // validate channels for HT40 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT40/HT40'
    //     );
    //   }
    //   // end
    //   // validate channels for HT80 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT80/HT80'
    //     );
    //   }
    //
    //   // end
    // },

        calculateCapabilities(configInterface, stateInterface, interfaceCapabilities, interfaceName) {
      if (!interfaceCapabilities) {
        return;
      }

      const config = configInterface;

      let selectedChannel = null;
      if (stateInterface) {
        selectedChannel = stateInterface.channel;
      } else {
        selectedChannel = configInterface.channels[0];
      }
      if (!interfaceName) return;

      this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);

      if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
        config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
      }

      // validate channels for HT40 & 2.4
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
        this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'HT40/HE40'
        );
      }
      // end validate

      // validate channels for HT40 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT40/HT40/HE40'
        );
      }
      // end
      // validate channels for HT80 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80/HT80/HE80'
        );
      }

      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT160' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE160') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80+80/VHT160/HE80+80/HE160'
        );
      }

      // end
    },

    calculateClientsByFrequency(item, frequency) {
      let result = '';
      const wifiState = item.state.wifi;
      if (!wifiState) {
        return result;
      }
      const ID = Object.entries(wifiState)
        .filter(([key, value]) => value.frequency === frequency)
        .map((it) => it[0])[0];
      if (!ID) {
        return result;
      }
      const active = this.wlansState(item, ID).active.length;
      if (active) {
        result = active;
      }
      return result;
    },
    calculateWiredVLANs(wired) {
      return wired.reduce((acc, wiredInterface) => {
        if (wiredInterface.hasOwnProperty('vlans') && wiredInterface.id.includes('eth')) {
          return (acc += wiredInterface.vlans.length);
        }
        return acc;
      }, 0);
    },
    calculateWiredClients(clients) {
      const ethKeys = Object.keys(clients).filter((client) => client.includes('eth'));
      if (ethKeys.length === 0) {
        return '';
      }
      return ethKeys.reduce((acc, current) => clients[current].total + acc, 0);
    },
    clearChanList(id) {
      this.interfaceCapabilities[id].selected.channels = [];
    },
    toggleEdit(e) {
      if (e.target.dataset.action === 'search') {
        return;
      }
      this.isFWUpgradeShow = false;
      this.isFirewallShow = false;
      this.statLogLbsmodal = false;
      const selectedNode = e.target.closest('[data-index]');
      const index = parseInt(selectedNode.dataset.index);
      const { id } = selectedNode.dataset;
      const { status } = selectedNode.dataset;
      if (e.target.dataset.action === 'delete') {
        this.openDeletingCpeDialog(id);
        return;
      }
      this.interfaceSettings.radio0 = false;
      this.interfaceSettings.radio1 = false;
      this.interfaceSettings.radio2 = false;
      this.forceFWObjects = false;
      if (!this.selectedCpesId.length) {
        this.$store.commit('setCpesEditIndex', index);
        this.$store.commit('setCpesEditId', id);
      }
      SNMPMonitoringConfigService.getSNMPConfigForCPE(this, this.cpeEditCurrent);
    },
    changePosition(value) {
      const editBlock = document.querySelector('.cpes-edit .row');
      if (editBlock) {
        editBlock.style.position = value;
      }
    },
    disableSingleEditMode() {
      this.updatedSNMPMonitoringConfig = {};
      this.commonUpdateCpesData.description = '';
      this.commonUpdateCpesData.commonSelectedWlans = [];
      this.$store.commit('setCpesEditIndex', null);
      this.$store.commit('setCpesEditId', null);
      this.updatedCpe = null;
      this.newTemplateData = {
        name: '',
        description: '',
        base_location: '',
        model: '',
        is_auto: false,
        cpes: [],
        template: {
          cpe_config_template: false
        }
      };
    },
    // Возвращает к исходному окну
    resetMultipleEditMode() {
      this.commonDeletingWlansModal = false;
      this.commonDeletingTagsModal = false;
      this.commonDeletingCpesModal = false;
      this.editCpesSettingsMode = false;
      this.addTagsModal = false;
      this.assignCommonWlanMode = false;
      this.setTemplateMode = false;
      this.logSettingsMode = false;
      this.accessControlMode = false;
      this.allChecked = false;
      this.updateCommonCpesMode = false;
      this.CpesFwUpgradeMode = false;
      this.commonDeletingTagsModal = false;
      this.commonSelectedWlans = [];
      this.isChangeLocationOpen = false;
    },
    disableMultipleEditMode() {
      this.$store.commit('setCpesEditId', false);
      this.updatedCpe = null;
      this.selectedCpesId = [];
      this.massLocationId = this.$store.state.userData.base_location;
    },
    openCpeWizard() {
      this.$router.push('registerCpe');
    },
    wlansState(cpe, id) {
      const stateOfWlans = {
        active: [],
        notActive: [],
        updating: []
      };
      const cpeConfig = helpers.transformArrayToObject(cpe.config.wifi);
      if (!cpeConfig[id]) {
        return stateOfWlans;
      }
      const wlansConfigArray = cpeConfig[id].wlans;
      if (!cpe.state.wifi) {
        return stateOfWlans;
      }
      const wlanStates = cpe.state.wifi[id].wlanstates;

      wlansConfigArray.forEach((wlanId) => {
        if (wlanStates[wlanId] && wlanStates[wlanId].state === 'ENABLED') {
          if (commonService.wlanNamebyId(wlanId)) {
            stateOfWlans.active.push(commonService.wlanNamebyId(wlanId));
          }
        } else if (!wlanStates[wlanId] || wlanStates[wlanId].state === 'DISABLED') {
          if (commonService.wlanNamebyId(wlanId)) {
            stateOfWlans.notActive.push(commonService.wlanNamebyId(wlanId));
          }
        } else if (commonService.wlanNamebyId(wlanId)) {
          stateOfWlans.updating.push(commonService.wlanNamebyId(wlanId));
        }
      });
      return stateOfWlans;
    },
    isBusy(id, status) {
      return (
        this.cpesIdsOperations.includes(id) ||
        status === 'updating' ||
        status === 'upgrading' ||
        status === 'rebooting' ||
        status === 'resetting'
      );
    },
    errorStatus(error) {
      const erorrDiv = document.createElement('div');
      erorrDiv.innerHTML = '<span class="text-danger mb-h"><b>Last error</b></span>';
      const erorrList = document.createElement('ul');
      erorrDiv.className = 'error-status small';
      erorrList.className = 'list-unstyled mb-h';

      erorrDiv.appendChild(erorrList);

      if (error.type) {
        erorrList.innerHTML += `<li><b>Type:</b> ${error.type}</li> `;
      }
      if (error.module) {
        erorrList.innerHTML += `<li><b>Module:</b> ${error.module}</li> `;
      }
      if (error.module_id) {
        erorrList.innerHTML += `<li><b>Module ID:</b> ${error.module_id}</li> `;
      }
      if (error.description) {
        erorrList.innerHTML += `<li class="mt-q">${error.description}</li> `;
      }

      erorrList.innerHTML += "<li class='mt-q'>Click to watch events</li>";

      return erorrDiv;
    },
    checkCpe(e) {
      this.disableSingleEditMode();
      const selectedNode = e.target.closest('[data-index]');
      const index = parseInt(selectedNode.dataset.index);
      const { id } = selectedNode.dataset;
      if (this.selectedCpesId.includes(id)) {
        this.selectedCpesId.splice(this.selectedCpesId.indexOf(id), 1);
        if (this.selectedCpesId.length === 0) {
          this.updateCommonCpesMode = false;
        }
      } else {
        this.selectedCpesId.push(id);
      }
      if (!this.checkCpesModel()) {
        this.resetMultipleEditMode();
      }
      if (!this.checkCpesConnectedState()) {
        this.resetMultipleEditMode();
      }
      let cpeObj;
      if (this.selectedCpesId[0]) {
        cpeObj = JSON.parse(JSON.stringify(commonService.cpeObjbyId(this.selectedCpesId[0])));
        this.updatedCpe = JSON.parse(JSON.stringify(cpeObj));
      } else {
        this.$store.commit('setCpesEditId', null);
        return;
      }

      // Находим capabilities
      const modelId = this.updatedCpe.model.id;
      const { cpeModelsList } = this.$store.state;
      cpeModelsList.forEach((model) => {
        if (model.id === modelId) {
          this.cpeModelData = model;
        } else {
        }
      });

      // Проверяем корректность capabilities
      if (!this.cpeModelData || !this.cpeModelData.caps.wifi || !Object.keys(this.cpeModelData.caps.wifi).length) {
        this.notCorrectCapabilities = true;
      } else {
        this.notCorrectCapabilities = false;
      }

      this.checkRfCollision();
    },
    checkAllCpes() {
      const cpesLength = this.filteredCpes.length;
      const filtredSelectedId = document.querySelectorAll('.cpe-row');
      if (cpesLength) {
        if (!this.isAllChecked) {
          filtredSelectedId.forEach((cpe) => {
            if (cpe.dataset.status != 'updating' && cpe.dataset.status != 'upgrading') {
              if (!cpe.classList.contains('cpe-loading') && !this.selectedCpesId.includes(cpe.dataset.id)) {
                this.selectedCpesId.push(cpe.dataset.id);
              }
            }
          });
        } else if (this.isAllChecked) {
          this.selectedCpesId = [];
          this.disableSingleEditMode();
          this.updateCommonCpesMode = false;
        }
      }
      let cpeObj;
      if (this.selectedCpesId[0]) {
        cpeObj = JSON.parse(JSON.stringify(commonService.cpeObjbyId(this.selectedCpesId[0])));
        this.updatedCpe = JSON.parse(JSON.stringify(cpeObj));
      } else {
        this.$store.commit('setCpesEditId', null);
        return;
      }

      // Находим capabilities
      const modelId = this.updatedCpe.model.id;
      const { cpeModelsList } = this.$store.state;
      cpeModelsList.forEach((model) => {
        if (model.id === modelId) {
          this.cpeModelData = model;
        } else {
        }
      });

      this.checkRfCollision();
    },
    filterItems(items) {
      let filteredCpes = items;

      filteredCpes = Filters.cpeSearchQuery(filteredCpes, this.filters.query);
      this.filteredCpes = filteredCpes;
      // console.log(filteredCpes);
      return filteredCpes;
    },
    setFilter(filterName, value) {
      if (this.muteCpeFilters) {
        return;
      }
      this.selectedCpesId = [];
      this.allChecked = false;
      const checkAll = document.getElementById('checkAll');
      if (checkAll) {
        checkAll.checked = false;
      }
      this.disableSingleEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
      } else {
        this.filters[filterName] = null;
      }
      this.refreshCpesListQuery();
    },
    refreshCpesListQuery() {
      this.selectedCpesId = [];
      this.allChecked = false;
      this.disableSingleEditMode();

      this.offset = 0;
      this.$store.commit('setCpesEditId', null);
      this.$store.commit('setCpesList', false);
      this.canLoadMore = true;
      // cpeService.refreshCpes(this, 'spinner');

      if (this.beforeDestroyHookExecutedFlag === false) {
        // нужно для исправления бага по задачеке WNE-2306
        // console.log('refresh');
        cpeService.refreshCpes(this, 'spinner');
      } else {
        // console.log('beforeDestroy');
      }
    },
    cancelEdit() {
      this.selectedCpesId = [];
      this.allChecked = false;
      const checkAll = document.getElementById('checkAll');
      if (checkAll) {
        checkAll.checked = false;
      }
      this.disableSingleEditMode();
    },
    cancelCommonEdit() {
      this.commonDeletingWlansModal = false;
      this.commonDeletingTagsModal = false;
      this.commonDeletingCpesModal = false;
      this.editCpesSettingsMode = false;
      this.addTagsModal = false;
      this.assignCommonWlanMode = false;
      this.logSettingsMode = false;
      this.accessControlMode = false;
      this.selectedCpesId = [];
      this.allChecked = false;
      const checkAll = document.getElementById('checkAll');
      if (checkAll) {
        checkAll.checked = false;
      }
      this.disableSingleEditMode();
    },
    updateCpe() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.updatedCpe.config.lbs_config.filtermode === 'WhiteList' && this.newMacAddress) {
            if (this.configFilterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.whitelist.push(this.newMacAddress);
              this.newMacAddress = '';
            } else {
              return;
            }
          }

          if (this.updatedCpe.config.lbs_config.filtermode === 'BlackList' && this.newMacAddress) {
            if (this.configFilterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.blacklist.push(this.newMacAddress);
              this.newMacAddress = '';
            } else {
              return;
            }
          }

          if (this.firewallL3List.mac_list.indexOf(this.newL3MacAddress) == -1 && this.newL3MacAddress) {
            this.firewallL3List.mac_list.push(this.newL3MacAddress);
          }
          this.newL3MacAddress = '';

          if (this.firewallL3List.ip_list.indexOf(this.newL3IPAddress) == -1 && this.newL3IPAddress) {
            this.firewallL3List.ip_list.push(this.newL3IPAddress);
          }
          this.newL3IPAddress = '';

          cpeService.updateCpe(this);
        }
      });
    },
    updateSNMPMonitoringConfig() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          SNMPMonitoringConfigService.updateSNMPMonitoringConfig(this);
        }
      });
    },
    createSNMPMonitoringConfig() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          SNMPMonitoringConfigService.createSNMPMonitoringConfig(this);
        }
      });
    },
    deleteSNMPMonitoringConfig() {
      SNMPMonitoringConfigService.deleteSNMPMonitoringConfig(this);
    },
    clearLogSettings() {
      this.logSettingsData.lbs_config.enabled = true;
      this.logSettingsData.log_config.enabled = true;
      this.logSettingsData.stats_config.enabled = true;

      this.logSettingsData.lbs_config.reportperiod = '';
      this.logSettingsData.log_config.reportperiod = '';
      this.logSettingsData.stats_config.reportperiod = '';
    },
    assignCommonWlanShow() {
      this.assignCommonWlanMode = !this.assignCommonWlanMode;
    },
    resetMaxClients(val, id) {
      if (!val) {
        this.interfaceCapabilities[id].selected.maxclients = '';
      }
    },
    setTemplate() {
      this.setTemplateMode = !this.setTemplateMode;
    },
    editCpesSettingsShow() {
      if (this.checkCpesModel()) {
        this.editCpesSettingsMode = true;
      } else {
        this.editCpesSettingsMode = false;
      }
    },
    LogSettingsShow() {
      this.logSettingsMode = !this.logSettingsMode;
    },
    acessControlSettingsShow() {
      this.accessControlMode = !this.accessControlMode;
    },
    fwUpgradeShow() {
      this.CpesFwUpgradeMode = !this.CpesFwUpgradeMode;
    },
    assignCommonWlan() {
      cpeService.assignCommonWlan(this);
    },
    assignTags() {
      cpeService.assignTags(this);
    },
    updateCpesRadioSettings() {
      cpeService.updateCpesRadioSettings(this);
    },
    updateCommonCpes() {
      cpeService.updateCommonCpes(this);
    },
    setTemplatesToCPEs(id, sourceObject) {
      cpeService.setTemplatesToCPEs(this, id, sourceObject);
    },
    updateLogSettings() {
      this.$validator
        .validateAll({
          stat_reportperiod: this.logSettingsData.stats_config.reportperiod,
          log_reportperiod: this.logSettingsData.log_config.reportperiod,
          lbs_reportperiod: this.logSettingsData.lbs_config.reportperiod
        })
        .then((result) => {
          if (result) {
            cpeService.updateLogSettings(this);
          }
        });
    },
    updateAccessControl() {
      cpeService.updateAccessControlSettings(this);
    },
    refreshCpesList() {
      // this.cancelCommonEdit();
      cpeService.refreshCpes(this);
    },
    checkRfCollision() {
      let RF24 = 0;
      let RF50 = 0;
      let NoRF = 0;
      // Плохая производительность этой конструкции на 300+ элементах
      // чтобы хоть как-то ее ускорить юзаем побитовые логические операции
      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id)) {
          if (cpe.state && cpe.state.wifi && cpe.state.wifi.length) {
            cpe.state.wifi.forEach((rf) => {
              if (rf.frequency === '2.4') {
                RF24++;
              } else if (rf.frequency === '5') {
                RF50++;
              }
            });
          } else {
            NoRF++;
          }
        }
      });
      return (RF24 && RF50) || NoRF;
    },
    checkCpesModel() {
      let result = true;
      const modelsArray = [];
      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id)) {
          modelsArray.push(cpe.model.id);
        }
      });
      modelsArray.forEach((model) => {
        if (modelsArray[0] != model) {
          result = false;
        }
      });
      return result;
    },
    checkIpsecTunnelType(tunnelType) {
      if (tunnelType === 'ipsec_noencrypt' || tunnelType === 'ipsec') {
        return true;
      }
      return false;
    },
    checkCpesHasIpsecTunnelType() {
      // возвращаем true если все выбранные точки с типом подключения ipsec_noencrypt или ipsec
      // и false если есть с другим типом
      let result = false;
      const cpesIpsecStatus = [];
      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id)) {
          cpesIpsecStatus.push(this.checkIpsecTunnelType(cpe.state.l2tp_state.tunnel_type));
        }
      });
      // console.log(cpesIpsecStatus)
      // если хотя бы одна из точек не с ipsec ставим результат в false
      if (cpesIpsecStatus.includes(false)) {
        result = false;
      } else {
        result = true;
      }
      return result;
    },
    checkCpesDisconnectedState() {
      let result = true;
      const modelsArray = [];

      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id) && cpe.connected) {
          result = false;
        }
      });
      return result;
    },
    checkCpesConnectedState() {
      let result = true;
      const modelsArray = [];

      this.$store.state.cpesList.forEach((cpe) => {
        if (this.selectedCpesId.includes(cpe.id) && !cpe.connected) {
          result = false;
        }
      });
      return result;
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     query: '',
    //     interfacesFilter: null,
    //     connectedFilter: null,
    //     activeFilter: null,
    //     lastError: null,
    //     location: 'All locations',
    //     models: 'All models',
    //     tag: 'All tags',
    //     group: 'No RRM filter',
    //     with_childs: false,
    //   };
    //   this.searchValue = '';
    //   this.refreshCpesListQuery();
    // },
        resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        query: '',
        interfacesFilter: null,
        radarFilter: null,
        connectedFilter: null,
        activeFilter: null,
        scanningFilter: null,
        lastError: null,
        // location: 'All locations',
        // with_childs: false,
        models: 'All models',
        tag: 'All tags',
        group: 'No RRM filter',
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.searchValue = '';
      this.refreshCpesListQuery();
    },
    showNewTemplate() {
      this.newTemplateData.template.cpe_config_template = JSON.parse(JSON.stringify(this.updatedCpe.config));
      this.newTemplateData.template.location = this.updatedCpe.base_location;
      this.newTemplateData.base_location = this.$store.state.userData.base_location;
      this.newTemplateData.model = this.updatedCpe.model.id;
      this.newTemplateModal = true;
    },

    updateCpeInterface() {
      this.enableSaveChanges = true;
      // multiselect depends on this method, do not delete it
    },
    showId(e) {
      const selectedNode = e.target.closest('[data-id]');
      const { id } = selectedNode.dataset;
      if (!this.cpeModelData || !this.cpeModelData.caps.wifi || !this.cpeModelData.caps.wifi[id]) {
        return;
      }
      this.interfaceSettings[selectedNode.dataset.id] = !this.interfaceSettings[selectedNode.dataset.id];
    },
    showLogStatLbs() {
      this.statLogLbsmodal = !this.statLogLbsmodal;
    },
    openCommonDeletingWlansDialog() {
      this.commonDeletingWlansModal = true;
    },
    openCommonDeletingTagsDialog() {
      this.commonDeletingTagsModal = true;
    },
    openIPsecModal() {
      this.IPsecModal = true;
      this.IPsecOption = '';
    },
    openRebootCpeRedundantModal() {
      this.rebootCpeRedundantModal = true;
    },
    openRebootCpeAgentModal() {
      this.rebootCpeAgentModal = true;
    },
    openConfigModal() {
      this.configModal = true;
      this.configCommand = '';
    },
    openRebootCpeModal() {
      this.rebootCpeModal = true;
    },
    openResetCpeModal() {
      this.resetCpeModal = true;
    },
    openCommonDeletingCpesDialog() {
      this.commonDeletingCpesModal = true;
    },
    openDeletingCpeDialog(id) {
      this.cpeForDelete = id;
      this.deletingCpeModal = true;
    },
    openAddTagsDialog() {
      this.addTagsModal = true;
    },
    openFWUpgradeDialog() {
      this.FWUpgradeModal = true;
    },
    getWebAccess() {
      cpeService.getWebAccess(this);
    },
    deleteCpe() {
      cpeService.deleteCpe(this);
    },
    deleteCommonWlans() {
      cpeService.deleteCommonWlans(this);
    },
    deleteCommonTags() {
      cpeService.deleteCommonTags(this);
    },
    deleteCommonCpes() {
      cpeService.deleteCommonCpes(this);
    },
    rebootCpeAgent(cpeIds) {
      cpeService.rebootCpeAgent(this, cpeIds);
    },
    rebootCpeRedundant(cpeIds) {
      cpeService.rebootCpeRedundant(this, cpeIds);
    },
    updateCPEIPsec(cpeIds, IPsecOption) {
      cpeService.updateCPEIPsec(this, cpeIds, IPsecOption);
    },
    issueCPECommand(cpeIds, command) {
      cpeService.issueCPECommand(this, cpeIds, command);
    },
    rebootCPE(cpeIds) {
      cpeService.rebootCPE(this, cpeIds);
    },
    changeLocations(list, id) {
      cpeService.changeCpesLocations(this, list, id);
    },
    resetCPE(ids, broker_ip) {
      cpeService.resetCPE(this, ids, broker_ip);
    },
    createTemplate() {
      this.$validator
        .validateAll({
          'template-name': this.newTemplateData.name
        })
        .then((result) => {
          if (result) {
            cpeService.createTemplateFromCpe(this);
          }
        });
    },
    cancelDeleteCommonWlans() {
      this.commonDeletingWlansModal = false;
    },
    cancelDeleteCommonTags() {
      this.commonDeletingTagsModal = false;
    },
    cancelChangeLocation() {
      this.isChangeLocationOpen = false;
    },
    cancelDeleteCommonCpes() {
      this.commonDeletingCpesModal = false;
    },
    cancelDeleteCpe() {
      this.deletingCpeModal = false;
    },
    checkCpeLoading(id) {
      this.cpeOperations.forEach((operation) => {
        if (operation.model_id === id) {
          return true;
        }
      });
    },
    wlanObj(id) {
      const wlanObj = commonService.wlanObjbyId(id);
      return wlanObj;
    },
    cpeObj(id) {
      const cpeObj = commonService.cpeObjbyId(id);
      return cpeObj;
    },
    modelObj(id) {
      const modelObj = commonService.modelObjbyId(id);
      return modelObj;
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    firewallName(id) {
      const showFirewallFromId = commonService.firewallNamebyId(id);
      return showFirewallFromId;
    },
    cutName(name) {
      const cutName = commonService.cutName(name);
      return cutName;
    },
    formatBytes(bytes, decimals) {
      const formatBytes = commonService.formatBytes(bytes, decimals);
      return formatBytes;
    },
    activeChannel(channelList, currChannel) {
      let result;
      channelList.forEach((channel) => {
        if (channel === currChannel) {
          result = true;
        }
      });
      return result;
    },
    addMacAddress() {
      this.$validator.validateAll({ mac: this.newMacAddress }).then((result) => {
        if (result) {
          if (this.updatedCpe.config.lbs_config.filtermode === 'WhiteList' && this.newMacAddress) {
            if (this.configFilterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.whitelist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          if (this.updatedCpe.config.lbs_config.filtermode === 'BlackList' && this.newMacAddress) {
            if (this.configFilterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.blacklist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          this.newMacAddress = '';
          this.$validator.reset();
        }
      });
    },
    addTag() {
      this.$validator
        .validateAll({
          tag: this.newTag
        })
        .then((result) => {
          if (result && this.newTag) {
            if (this.updatedCpe.tags.indexOf(this.newTag) == -1) {
              this.updatedCpe.tags.push(this.newTag);
              this.newTag = '';
            } else {
              return;
            }
            this.newTag = '';
          }
        });
    },
    addTagMultiple() {
      this.$validator
        .validateAll({
          tags: this.newTag
        })
        .then((result) => {
          if (result && this.newTag) {
            this.newTags.push(this.newTag);
            this.newTag = '';
          }
        });
    },
    searchTag(tag) {
      this.filters.tag = tag;
      this.refreshCpesListQuery();
    },
    addExistTag(tag) {
      if (this.updatedCpe.tags.indexOf(tag) == -1) {
        this.updatedCpe.tags.push(tag);
      } else {
      }
    },
    addExistTagMultiple(tag) {
      this.newTags.push(tag);
    },
    removeTag(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.updatedCpe.tags.splice(index, 1);
    },
    removeTagMultiple(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.newTags.splice(index, 1);
    },
    removeMac(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { list } = selectedNode.dataset;
      if (list === 'whitelist') {
        this.configFilterList.whitelist.splice(index, 1);
      }
      if (list === 'blacklist') {
        this.configFilterList.blacklist.splice(index, 1);
      }
    },

    showFWUpgrade() {
      this.isFWUpgradeShow = !this.isFWUpgradeShow;
    },
    showFirewall() {
      this.isFirewallShow = !this.isFirewallShow;
    },
    previewUuids(event) {
      const that = this;
      function showCsv() {
        const input = event.target;
        if (input.files && input.files[0] && input.files[0].type === 'text/csv') {
          const reader = new FileReader();
          reader.onload = (e) => {
            const result = JSON.stringify(e.target.result).replace(/["/]*/g, '');
            const uuids = result.split('\\n');
            uuids.forEach((id) => {
              if (id.length != 36) {
                that.invalidUuid = true;
              }
            });
            if (that.invalidUuid) return;
            const newUuidArray = that.newTemplateData.cpes.concat(uuids);
            that.newTemplateData.cpes = newUuidArray;
          };
          reader.readAsText(input.files[0]);
          input.value = '';
          that.invalidUuid = false;
          that.fileTypeError = false;
        } else {
          that.invalidUuid = false;
          that.fileTypeError = true;
        }
      }
      showCsv();
    },
    addUUIID() {
      this.$validator
        .validateAll({
          uuid: this.newUuid
        })
        .then((result) => {
          if (result) {
            if (this.newTemplateData.cpes.indexOf(this.newUuid) == -1) {
              this.newTemplateData.cpes.unshift(this.newUuid);
              this.newUuid = '';
            } else {
              this.newUuid = '';
            }
          }
        });
    },
    removeUuid(e) {
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.newTemplateData.cpes.splice(index, 1);
    },
    getHostname() {
      if (API_URL) {
        const restApi = API_URL.split(':');
        if (restApi[2]) {
          restApi.splice(2, 1);
          this.hostname = restApi.join(':');
        } else {
          this.hostname = restApi.join(':');
        }
      } else {
        this.hostname = `http://${window.location.host}`;
      }
    },
    changeFwSource() {
      this.fwFromLink = !this.fwFromLink;
      if (this.fwFromLink) {
        this.forceFWObjects = { url: '' };
      } else {
        this.forceFWObjects = false;
      }
    },
    uploadFWFileToServer() {
      const fwfile = document.querySelector('#fwfile');
      if (!fwfile.files.length) {
        return;
      }
      const formData = new FormData();
      formData.append(fwfile.name, fwfile.files[0]);
      this.$store.commit('toggleUploadingFW', true);
      this.axios.post(`${API_URL || ''}/api-static/firmwares/upload`, formData).then(
        (response) => {
          if (response.data.status === 'success') {
            this.uploadFWfile = response.data.data.items;
            this.uploadFWfile.version = `${fwfile.files[0].name} ( uploaded ) `;
            // this.updatedCpe.config.firmware.file = JSON.parse(JSON.stringify(this.uploadFWfile.url));
            this.cpeModelData.firmwares.push(this.uploadFWfile);
            this.forceFWObjects = this.uploadFWfile;
            this.forceFWObjects.url = `http://${this.updatedCpe.state.l2tp_state.host_addr}${this.forceFWObjects.url}`;
            VueNotifications.success({ message: 'FW File Uploaded Successfully' });
            this.$store.commit('toggleUploadingFW', false);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            this.$store.commit('toggleUploadingFW', false);
          }
        },
        (err) => {
          this.$store.commit('toggleUploadingFW', false);
          VueNotifications.error({ message: err });
          this.error = err;
        }
      );
    },
    upgradeFW() {
      const { body } = document;
      body.classList.remove('modal-open');
      this.FWUpgradeModal = false;
      cpeService.upgradeFW(this);
    },
    handlerChannels(isCisco, channels, channel, isActive, id) {
      if (!isCisco || !isActive) {
        return;
      }
      this.interfaceCapabilities[id].selected.channels = [channel];
    },
    formatToCSV(data) {
      if (!data) {
        return [];
      }
      return data.map(this.formatItemToCSV);
    },
    formatItemToCSV(item) {
      return {
        Name: item.name,
        Model: item.model.name,
        Status: item.config_status,
        '2.4 Ghz Clients': this.calculateClientsByFrequency(item, '2.4'),
        '5 Ghz Clients': this.calculateClientsByFrequency(item, '5'),
        'Wired Clients': this.calculateWiredClients(item.clients),
        Radar: item.config.lbs_config.enabled ? 'enabled' : '',
        Location: this.locationName(item.base_location),
        Tags: item.tags.join(' '),
        MAC: item.state.network.macaddr,
        Firmware: item.state.firmware.version.version
      };
    },
    handleCloseConfigScript() {
      this.configModal = false;
      this.commandResult = '';
    },
    showMaxinactivityModalInfoDetails() {
      // console.log('clicked');
      this.showingMaxinactivityModalInfoDetails = true;
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.checkCpeLoading();
    });
  },
  beforeDestroy() {
    this.beforeDestroyHookExecutedFlag = true;
    this.resetAllFilters();
  },
  // created() {
  //   // if (this.showDistressed) {
  //   //   this.setFilter('lastError', true);
  //   //   // this.filters.lastError = true;
  //   // }
  //   this.disableSingleEditMode();
  //   this.$store.commit('setCpesList', false);
  //   cpeService.getPaginatedCpes(this, this.limit, this.offset);
  //   if (!String.prototype.format) {
  //     String.prototype.format = function () {
  //       const args = arguments;
  //       return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
  //     };
  //   }
  //   // rrmService.getRRMs(this);
  //   // templatesService.getCpeModels(this);
  //   // templatesService.getCpeTemplates(this);
  //   // cpeService.getCpesTags(this);
  //   // wlanService.getAllWlans(this);
  //   // wlanService.getHosts(this);
  //   // redirectsService.getRedirectsRule(this);
  //   this.getHostname();
  // }
  created() {
    // if (this.showDistressed) {
    //   this.setFilter('lastError', true);
    //   // this.filters.lastError = true;
    // }
    this.disableSingleEditMode();
    this.$store.commit('setCpesList', false);

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    cpeService.getPaginatedCpes(this, this.limit, this.offset);
    if (!String.prototype.format) {
      String.prototype.format = function () {
        const args = arguments;
        return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
      };
    }
    // rrmService.getRRMs(this);
    // templatesService.getCpeModels(this);
    // templatesService.getCpeTemplates(this);
    // cpeService.getCpesTags(this);
    // wlanService.getAllWlans(this);
    // wlanService.getHosts(this);
    // redirectsService.getRedirectsRule(this);
    this.getHostname();
  }
};
</script>
<style lang="scss">
.cpes {
  display: flex;
}

.cpes-page {
  min-width: 900px;
}

.cpe-logo {
  vertical-align: baseline;
  height: 15px;
}

.cpes-list,
.cpes-edit {
  transition: width 0.2s;
}

.cpes-list {
  display: inline-block;
  width: 100%;
}

.cpes-edit {
  display: inline-block;
  width: 0%;
  word-wrap: break-word;
}

.cpe-interface-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__badge {
    display: flex;
  }

  &__badge .badge {
    margin-right: 1px;
  }
}

.cpes-edit .card-header {
  border: 0px;
}

.cpes-edit .btn {
  white-space: normal;
}

/*Edit frame for CPE with interface*/
.edit-visible .cpes-list {
  transition: width 0.2s;
  margin-right: 10px;
  width: 60%;
}

.edit-visible .cpes-edit {
  transition: width 0.2s;
  width: 40%;
  min-width: 500px;
}

/* Edit frame for CPE without interface */
.edit-visible.interface-visible .cpes-list {
  transition: width 0.2s;
  width: 45%;
}

.edit-visible.interface-visible .cpes-edit {
  transition: width 0.2s;
  width: 55%;
}

/*Table style*/
.cpe-row {
  transition: background 0.15s;
  cursor: pointer;
}

.cpe-row.selected {
  background: #d9e3ec;
}

.cpe-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.cpe-row-nodata {
  text-align: center;
}

.cpe-action {
  transition: transform 0.2s;
}

.channel-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  /*padding: 0px;*/
  list-style: none;
  margin-left: 0.5rem;

  font-size: 80%;
}

.channel-list input[type='checkbox'] {
  display: none;
}

.channel-list label {
  width: 100%;
  cursor: pointer;
}

.channel-list-item {
  padding: 0px 0px;
  transition: border-bottom-color 0.5s;
  cursor: pointer;
}

/*CPE settings */
.cpe-settings {
  display: flex;
  justify-content: flex-start;
}

.cpe-buttons {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.main-settings {
  flex-basis: auto;
  margin-right: 0rem;
}

.interface-block {
  border-radius: 10px;
  padding: 10px;
  background: rgba(236, 236, 236, 0.45);
  -webkit-box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
}

.interface-block-header {
  cursor: pointer;
}

.interface-block-icon {
  position: relative;
  bottom: 1px;
  display: inline-block;
  margin-right: 0.3rem;
  height: 15px;
}

.frequency-title {
  font-size: 0.7rem;
  position: relative;
  bottom: 2px;
}

.interface-visible .main-settings {
  flex-basis: 55%;
  margin-right: 2rem;
}

.interface-channel-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0rem 2rem;
}

.interface-channel {
  line-height: 2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50% !important;
  margin-right: 0.5rem;
}

.interface-channel-checkbox {
  display: none;
}

.interface-wlan-state {
  white-space: nowrap;
}

.interface-channel-trapezoid {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 20%;
  box-sizing: border-box;
  min-width: 30px;
  max-width: 70px;
  margin: 6px -1px;
  height: 0;
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 25px solid #bcc5d0;
  text-align: center;
  line-height: 25px;
  color: #fff;
  cursor: pointer;
  transition: border-bottom-color 0.5s;
}

.interface-channel-trapezoid__ht40 {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 25%;
  box-sizing: border-box;
  min-width: 50px;
  max-width: 100px;
  margin: 6px -1px;
  height: 0;
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 30px solid #bcc5d0;
  text-align: center;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
  transition: border-bottom-color 0.5s;
}

.interface-channel-trapezoid__ht80 {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 50%;
  box-sizing: border-box;
  min-width: 105px;
  max-width: 150px;
  height: 0;
  margin: 6px -1px;
  border: 10px solid rgba(0, 0, 0, 0);
  border-top: 0 solid;
  border-bottom: 40px solid #bcc5d0;
  border-radius: 1px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
  transition: border-bottom-color 0.5s;
  z-index: 10;
}

.interface-channel-trapezoid:hover {
  border-bottom-color: #8cd4a5;
}

.interface-channel-trapezoid__ht40:hover {
  border-bottom-color: #8cd4a5;
}

.interface-channel-trapezoid__ht80:hover {
  border-bottom-color: #8cd4a5;
}

.active.interface-channel-trapezoid {
  border-bottom-color: #4ebd75;
}

.active.interface-channel-trapezoid__ht40 {
  border-bottom-color: #4ebd75;
}

.active.interface-channel-trapezoid__ht80 {
  border-bottom-color: #4ebd75;
}

.interface-channel-trapezoid.disabled {
  cursor: not-allowed;
}

.interface-channel-trapezoid__ht40.disabled {
  cursor: not-allowed;
}

.interface-channel-trapezoid__ht80.disabled {
  cursor: not-allowed;
}

.label-disabled {
  cursor: not-allowed !important;
}

.interface-enter-active {
  transition: all 0.7s;
}

.interface-leave-active {
  transition: all 0.5s;
}

.interface-enter, .interface-leave-to /* .list-leave-active для <2.1.8 */ {
  opacity: 0;
  transform: translateY(-25px);
}

/*Style for filters*/
/*.btn.btn-outline-info:hover {*/
/*background-color: #82cee5;*/
/*}*/

/*.btn.btn-outline-info.active {*/
/*background-color: #63c2de;*/
/*}*/

.cpe-updating {
  opacity: 0.9;
  background: rgba(236, 236, 236, 0.45);
  /*cursor: not-allowed;*/
}

.cpe-wlans-state {
  position: relative;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.multiselect__content-wrapper {
  z-index: 1000 !important;
}

.vue-slider-dot {
  z-index: 10 !important;
}

.cpes-edit .btn-block.btn:disabled {
  margin-bottom: 0;
}

.cpes-edit .btn-block.btn-outline-success:disabled {
  margin-bottom: 4px;
}

.cpes-edit .btn:disabled + .text-danger {
  display: block;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.btn-link__disabled {
  cursor: pointer;
  color: #bcc5d0;
}

.btn-link__disabled:hover {
  cursor: pointer;
  color: #bcc5d0;
}

.tamplate-config p {
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

.edit-header__buttons .badge-upgrade {
  position: absolute;
  top: -6px;
  right: -5px;
}

/*.edit-header__buttons{*/
/*    position: absolute;*/
/*  top: 0px;*/
/*  right: 0px;*/
/*}*/

.badge-new-update {
  text-decoration: underline dashed;
  cursor: pointer;
  transition: background-color 0.2s;
}

.badge-new-update:hover {
  background-color: #4dbd74;
}

.cpes-page .filters__multiple .btn-group .btn {
  padding: 0.25rem 1rem;
}

.cpe-name {
  word-break: break-all;
}

.form-control__vlan {
  width: 55px;
}

// setting block header
.setting-block-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.setting-block-buttons {
  align-self: center;
  height: 100%;
}

.setting-block-buttons .icon-arrow-left:before,
.setting-block-buttons .icon-arrow-down:before {
  font-size: 20px;
}

.error-status {
  display: flex;
  flex-direction: column;

  padding: 4px;

  text-align: left;
}
</style>

<style lang="scss" scoped>
.filters__multiple .btn-group .btn {
  padding: 0.25rem 1rem;
}

.wlans-title {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  width: 100%;
}

.cpe-info {
  font-size: 80%;

  & .text-muted {
    color: #94a0b2 !important;
  }

  &--stats li:first-child {
    display: block;
  }

  &--stats li {
    display: inline-block;
    vertical-align: middle;

    margin-right: 5px;
  }
}

.cisco-icon {
  height: 20px;
  vertical-align: bottom;
}

.cpe-status {
  white-space: nowrap;
}

.cpe-edit-name {
  position: absolute;
  bottom: -17px;
  left: -8px;
}

.badge-status--cpe:after {
  top: 12px;
}

/* Moves button to top on laptop screens */
/* 1) Allows wrapping */
.filters-wrap {
  @media (max-width: 1440px) {
    flex-wrap: wrap;
  }
}

/* 2) Move button to top-right corner */
.filters-add {
  @media (max-width: 1440px) {
    order: -1;
    margin-left: auto;
    margin-bottom: 1rem;
  }
}

.custom-control {
  display: block;
}
</style>

<style lang="scss">
          .input-group-for-location-select-in-monitoringSNMP--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-monitoringSNMP--filters .select__list-container {
    width: 400px;
  }


.loader-backdrop-aps:after {
  position: absolute;
  top: 30px;
}
.no-scroll-bar {
  overflow-y: auto;

  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scroll-bar ::-webkit-scrollbar {
  /* chrome based */
  width: 0px; /* ширина scrollbar'a */
}
</style>

<style lang="scss" scoped>
.edit-header__buttons-in-snmp-config-monitor {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.loader-wrapper-in-SNMP-Monitoring-Config {
  min-height: 50px;
}
.info-tooltip-in-aps {
  margin-left: 0.2em;
}
.output-row {
  grid-column-start: 1;
  grid-column-end: 3;
}
</style>
